import React from "react";

const logBuffer = [];

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Log natif de l'erreur avec son stack trace complet
    console.error('Error caught in ErrorBoundary:', error);

    // Affiche les informations React spécifiques
    console.error('Component Stack:', errorInfo.componentStack);

    // Enregistrez ces informations dans l’état ou un outil de surveillance
    this.setState({ error, errorInfo });
  }

  handle_erase_market_settings= () => {
    localStorage.removeItem('pitsbi__realt__settingsMarket');
    // Reload the page
    window.location.reload();
  }

  handle_erase_settings = () => {
    localStorage.removeItem('pitsbi__realt__lastOpenedPopper');
    localStorage.removeItem('pitsbi__realt__settingsApiCoinGecko');
    localStorage.removeItem('pitsbi__realt__settingsDashboard');
    localStorage.removeItem('pitsbi__realt__settingsMap');
    localStorage.removeItem('pitsbi__realt__settingsMarket');
    localStorage.removeItem('pitsbi__realt__settingsStats');
    localStorage.removeItem('pitsbi__realt__settingsTrafic');
    localStorage.removeItem('pitsbi__realt__settingsTraffic');
    // Reload the page
    window.location.reload();
  };

  handle_erase_all_settings = () => {
    localStorage.clear();
    // Reload the page
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      {
        return (
          <div style={{ padding: '20px', color: 'red', background: 'lightgray' }}>
            <h1>Something went wrong.</h1>
            <h4>
              Send a screenshoot to{' '}
              <a href='https://t.me/Fredo_realT' target='_blank'>
                Pit's BI Developer
              </a>
            </h4>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              <summary>details</summary>
              <strong>Error :</strong> {this.state.error?.toString()}
              <br />
              <strong>Stack natif :</strong> <pre>{this.state.error?.stack}</pre>
              <br />
              <strong>React Stack :</strong> <pre>{this.state.errorInfo?.componentStack}</pre>
            </details>
            <br />
            <br />
            <h2>4 Steps to Try Resolving This Issue:</h2>
            <ul>
              <li>
                1. <a href='https://realt.pitsbi.io/about'>Go to the about page</a>
                <br />
                After that, go to the Dashboard page and refresh your wallet(s) (top right).
              </li>
              <li>
                2. <button onClick={this.handle_erase_market_settings}>Erase Specific Settings</button>
                <br />
                <small>This will remove the Market settings due to filter evolution. Issues may occur, and I apologize for that, but other saved data will remain intact.</small>
              </li>
              <li>
                3. <button onClick={this.handle_erase_settings}>Erase Specific Settings</button>
                <br />
                <small>This will remove specific settings like your preferences or recently used features, but will keep other saved data intact.</small>
              </li>
              <li>
                4. <button onClick={this.handle_erase_all_settings}>Erase All Settings</button>
                <br />
                <small>This will completely reset all saved settings and preferences to default values. Use this as a last resort.</small>
              </li>
            </ul>
          </div>
        );
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
