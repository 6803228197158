import React, { useContext, useEffect, useState } from 'react';
import { SnackbarContext } from '../../context/customsnackbar-context';
import { SettingsContext } from '../../context/settings-context';
import { MyThemeContext } from '../../context/theme-context';

import { Avatar, Badge, Box, CircularProgress, Paper, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { red } from '@mui/material/colors';

// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
// import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import StorefrontIcon from '@mui/icons-material/Storefront';
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import PurchasesOffersHead from './PurchasesOffers/PurchasesOffersHead';
import WalletPurchasesOffersHead from './PurchasesOffers/WalletPurchasesOffersHead';

const isCity = true;

function PurchasesOffers() {
  const { realTokens, holderWallets, balanceWallets, blockchainsClient, offersMarket } = useContext(SettingsContext);
  const { settingsMarket, settingsWallet } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const [tokens, setTokens] = useState(null);
  const [sortedOffers, setSortedOffers] = useState(null);
  const [sortedWalletOffers, setSortedWalletOffers] = useState(null);
  const [sortedWalletOffersCount, setSortedWalletOffersCount] = useState(0);
  const [offers, setOffers] = useState(null);

  const [cityList, setCityList] = useState(null);
  const [coinsList, setCoinsList] = useState(null);
  const [walletCoinsList, setWalletCoinsList] = useState(null);

  const [newOrModifyOffers, setNewOrModifyOffers] = useState([]);
  const [idMax, setIdMax] = useState(1);

  const [allOffers, setAllOffers] = useState(true);
  const [walletOffers, setWalletOffers] = useState(false);
  const [settingOffers, setSettingOffers] = useState(false);
  const [helpOffers, setHelpOffers] = useState(false);
  const [shopping, setShopping] = useState(false);

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  useEffect(() => {
    if (realTokens) {
      let cptTokens = [
        ...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0).filter((data) => !data.canal.includes(['exit_complete'])),
      ];

      if (balanceWallets) {
        const testContractxDai = (token, address) => {
          if (token.gnosisContract === address || token.xDaiContract === address) return true;
          else return false;
        };
        const testContractEth = (token, address) => {
          if (token.ethereumContract === address) return true;
          else return false;
        };

        cptTokens.forEach((token, index) => {
          token.walletsAmount = 0;
          token.walletAmount = 0;
          token.whiteList = false;
          if (holderWallets) {
            // if (holderWallets.filter((holderWallet) => holderWallet.checked)[0]) {
            holderWallets
              // .filter((holderWallet) => holderWallet.address === settingsWallet.selectedWallet)
              .forEach((holderWallet) => {
                if (balanceWallets && balanceWallets[holderWallet?.address]?.tokens?.['xdai']?.tokens?.length > 0 && typeof token.walletsAmount === 'number') {
                  balanceWallets[holderWallet.address].tokens['xdai'].tokens
                    .filter((t) => testContractxDai(token, t.token.id))
                    .forEach((t) => {
                      // console.log("token to find", t);
                      if (holderWallet.address === settingsWallet.selectedWallet) token.walletAmount += parseFloat(t.amount);
                      token.walletsAmount += parseFloat(t.amount);
                      // console.log("Add xdai wallets token", token.shortName, t, token.walletsAmount, holderWallet.address);
                    });
                }

                if (balanceWallets && balanceWallets[holderWallet?.address]?.tokens?.['eth']?.tokens?.length > 0 && typeof token.walletsAmount === 'number') {
                  balanceWallets[holderWallet.address].tokens['eth'].tokens
                    .filter((t) => testContractEth(token, t.token.id))
                    .forEach((t) => {
                      token.walletsAmount += parseFloat(t.currentATokenBalance / 10 ** t.reserve.decimals);
                      if (holderWallet.address === settingsWallet.selectedWallet) token.walletAmount += parseFloat(t.amount);
                    });
                }

                if (balanceWallets && balanceWallets[holderWallet?.address]?.tokens?.['rmm']?.tokens?.length > 0 && typeof token.walletsAmount === 'number') {
                  if (balanceWallets[holderWallet.address].tokens['rmm'].tokens) {
                    balanceWallets[holderWallet.address].tokens['rmm'].tokens
                      .filter((t) => testContractxDai(token, t.reserve.underlyingAsset))
                      .forEach((t) => {
                        token.walletsAmount += parseFloat(t.currentATokenBalance / 10 ** t.reserve.decimals);
                        if (holderWallet.address === settingsWallet.selectedWallet) token.walletAmount += parseFloat(t.currentATokenBalance / 10 ** t.reserve.decimals);
                      });
                  }
                }

                if (balanceWallets && balanceWallets[holderWallet?.address]?.tokens?.['rmmv3']?.tokens?.length > 0 && typeof token.walletsAmount === 'number') {
                  if (balanceWallets[holderWallet.address].tokens['rmmv3'].tokens) {
                    balanceWallets[holderWallet.address].tokens['rmmv3'].tokens
                      .filter((t) => testContractxDai(token, t.token.id))
                      .forEach((t) => {
                        token.walletsAmount += parseFloat(t.amount / 10 ** t.token.decimals);
                        if (holderWallet.address === settingsWallet.selectedWallet) token.walletAmount += parseFloat(t.amount / 10 ** t.token.decimals);
                      });
                  }
                }

                if (balanceWallets && balanceWallets[holderWallet?.address]?.tokens?.['pool']?.tokens?.length > 0 && typeof token.walletsAmount === 'number') {
                  if (balanceWallets[holderWallet.address].tokens['pool'].tokens) {
                    balanceWallets[holderWallet.address].tokens['pool'].tokens
                      .filter((t) => testContractxDai(token, t.pair.token0.id))
                      .forEach((t) => {
                        token.walletsAmount += parseFloat((t.liquidityTokenBalance * t.pair.token0.liquidity) / t.pair.totalSupply);
                        if (holderWallet.address === settingsWallet.selectedWallet)
                          token.walletAmount += parseFloat((t.liquidityTokenBalance * t.pair.token0.liquidity) / t.pair.totalSupply);
                        // console.log("Add pool0 wallets token", token.shortName, token.walletsAmount);
                      });
                    balanceWallets[holderWallet.address].tokens['pool'].tokens
                      .filter((t) => testContractxDai(token, t.pair.token1.id))
                      .forEach((t) => {
                        token.walletsAmount += parseFloat((t.liquidityTokenBalance * t.pair.token1.liquidity) / t.pair.totalSupply);
                        if (holderWallet.address === settingsWallet.selectedWallet)
                          token.walletAmount += parseFloat((t.liquidityTokenBalance * t.pair.token1.liquidity) / t.pair.totalSupply);
                        // console.log("Add pool1 wallets token", token.shortName, token.walletsAmount);
                      });
                  }
                }

                if ('whiteList' in balanceWallets[holderWallet.address]) {
                  if (balanceWallets[holderWallet.address].whiteList.tokens) {
                    balanceWallets[holderWallet.address].whiteList.tokens
                      .filter((t) => testContractxDai(token, t))
                      .forEach((t) => {
                        token.whiteList = true;
                      });
                  }
                }
              });
            // }
          }
        });
        setTokens(cptTokens);
      }
    }
  }, [realTokens, settingsWallet.selectedWallet]);

  useEffect(() => {
    if (!tokens) return;
    const testContractxDai = (token, address) => {
      if (token.gnosisContract === address || token.xDaiContract === address) return true;
      else return false;
    };
    let offers_swapcat = offersMarket.SwapCatOffersList.filter((offer) => offer.token_to_buy !== null && offer.token_to_pay !== null);
    offers_swapcat = offers_swapcat.map((offer) => {
      return {
        ...offer,
        sc: 'swapcat',
        token: tokens ? tokens.filter((token) => testContractxDai(token, offer.token_to_buy))[0] : null,
      };
    });
    let offers_yam = offersMarket.YamOffersList.filter(
      (offer) => offer.token_to_buy !== null && offer.token_to_pay !== null && offer.buy_holder_address === '0x0000000000000000000000000000000000000000'
    );
    offers_yam = offers_yam.map((offer) => {
      return {
        ...offer,
        sc: 'yam',
        token: tokens ? tokens.filter((token) => testContractxDai(token, offer.token_to_buy))[0] : null,
      };
    });
    let offers = offers_swapcat.concat(offers_yam);
    offers = offers.filter((offer) => offer.token);

    offers.forEach((offer, index) => {
      if (offer.token) {
        offer.shortName = offer.token.shortName;
        offer.fullName = offer.token.fullName;
        offer.city = offer.token.fullName.split(',').map((part) => part.trim())[1];
        offer.tokenPrice = offer.token.tokenPrice;
        offer.position = offer.token.walletsAmount;
        offer.positionWallet = offer.token.walletAmount ? offer.token.walletAmount : 0;
        offer.marketplaceLink = offer.token.marketplaceLink;
        offer.rent_start = offer.token.rentStartDate.date.slice(0, 10);
        offer.annualPercentageYield = offer.token.annualPercentageYield;
        offer.whiteList = offer.token.whiteList;
        offer.rentedUnits = offer.token.rentedUnits;
        offer.totalUnits = offer.token.totalUnits;

        offer.token_value_usd = 0;
        offer.token_to_pay_img = './images/unknown.png';
        offer.token_to_pay_name = 'unknown ???';
        const tokenToPaid = Object.values(blockchainsClient[settingsWallet.selectedBlockchain].coinList).find(
          (coin) => coin.address.toLowerCase() === offer.token_to_pay.toLowerCase()
        );
        if (!tokenToPaid) {
          offer.token_to_pay = null;
          // console.log("tokenToPaid unKnown ?", offer);
        }
        if (tokenToPaid) {
          offer.token_to_pay_img = tokenToPaid.imgToken;
          offer.token_to_pay_name = tokenToPaid.name;
          offer.token_value_usd = offer.token_value * tokenToPaid.toUSD;
        }
        offer.token_ratio_coin = (offer.token_value / offer.tokenPrice) * 1;
        offer.token_ratio = (offer.token_value_usd / offer.tokenPrice) * 1;
        const realT_ratio = 0.97 - 0.05 * (1 - offer.rentedUnits / offer.totalUnits) - offer.token_ratio_coin;
        offer.isRealT_price = -0.0001 < realT_ratio && realT_ratio < 0.0001 ? true : false;
        offer.token_newYield = (offer.annualPercentageYield * offer.tokenPrice) / 100 / offer.token_value_usd;
        if (offer.token.productType === 'loan_income') {
          offer.token_newYield = ((offer.annualPercentageYield * offer.tokenPrice) / 100 - (offer.token_value_usd - offer.tokenPrice)) / offer.token_value_usd;
        }
        offer.token_rented = offer.token.rentedUnits / offer.token.totalUnits;
        if (!offer.token_rented) offer.token_rented = 1;
        offer.last_pricing_days = -1;
        offer.nb_pricing = 0;
        if (offer.token.historic) {
          offer.nb_pricing = offer.token.historic.prices.length - 1;
          const prisingDate = offer.token.historic.prices[offer.token.historic.prices.length - 1].timsync;
          const prisingDateObj = new Date(prisingDate);
          const currentDate = new Date();
          const timeDifference = currentDate - prisingDateObj;
          const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          offer.last_pricing_days = daysDifference;
          if (offer.last_pricing_days < 0) offer.last_pricing_days = -1;
        }
        if (offer.token.shortName === 'RWA Holdings SA') offer.token.whiteList = true;
        // delete offer.token
      }
    });

    offers = offers.filter((offer) => offer.token_to_pay !== null);
    setOffers(offers);
  }, [tokens, newOrModifyOffers, settingsMarket.PurchaseOffers, settingsMarket.WalletPurchaseOffers, offersMarket.SwapCatOffersList, offersMarket.YamOffersList]);

  // ------------------------------------------------------------
  // -- Calculate and filter 'offers' for PurchasesOffersHead --
  // ------------------------------------------------------------
  useEffect(() => {
    if (!offers) return;

    // Filtrage des offres en fonctions des reglages
    let sortedOffers = [...offers];

    if (settingsMarket.PurchaseOffers.unselectedCity)
      sortedOffers = sortedOffers.filter((offer) => {
        const city = offer.fullName.split(',')[1].trim();
        return !settingsMarket.PurchaseOffers.unselectedCity.includes(city);
      });
    if (settingsMarket.PurchaseOffers.unselectedCoins)
      sortedOffers = sortedOffers.filter((offer) => {
        const coin = offer.token_to_pay_name;
        // console.log(coin,offer)
        return !settingsMarket.PurchaseOffers.unselectedCoins.includes(coin);
      });
    if (settingsMarket.PurchaseOffers.unselectPropertyType)
      sortedOffers = sortedOffers.filter((offer) => {
        const propertyType = offer.token.propertyType;
        return !settingsMarket.PurchaseOffers.unselectPropertyType.includes(propertyType);
      });
    if (settingsMarket.PurchaseOffers.isPosition)
      sortedOffers = sortedOffers.filter(
        (offer) => offer.position >= settingsMarket.PurchaseOffers.minPosition && offer.position < settingsMarket.PurchaseOffers.maxPosition
      );
    if (settingsMarket.PurchaseOffers.isOfferAmount)
      sortedOffers = sortedOffers.filter(
        (offer) => offer.token_amount >= settingsMarket.PurchaseOffers.minOfferAmount && offer.token_amount < settingsMarket.PurchaseOffers.maxOfferAmount
      );
    if (settingsMarket.PurchaseOffers.isRatioPrice)
      sortedOffers = sortedOffers.filter(
        (offer) => offer.token_ratio * 100 >= settingsMarket.PurchaseOffers.minRatioPrice && offer.token_ratio * 100 < settingsMarket.PurchaseOffers.maxRatioPrice
      );
    let o = settingsMarket.PurchaseOffers;
    // console.log("settingsMarket.PurchaseOffers.isRealtPrice",o.isRealtPrice,o.minRealtPrice,o.maxRealtPrice)
    if (settingsMarket.PurchaseOffers.isRealtPrice)
      sortedOffers = sortedOffers.filter(
        (offer) => offer.tokenPrice >= settingsMarket.PurchaseOffers.minRealtPrice && offer.tokenPrice < settingsMarket.PurchaseOffers.maxRealtPrice
      );
    // console.log("settingsMarket.PurchaseOffers.isRealtYield",o.isRealtYield,o.minRealtYield,o.maxRealtYield)
    if (settingsMarket.PurchaseOffers.isRealtYield)
      sortedOffers = sortedOffers.filter(
        (offer) => offer.annualPercentageYield >= settingsMarket.PurchaseOffers.minRealtYield && offer.annualPercentageYield < settingsMarket.PurchaseOffers.maxRealtYield
      );
    if (settingsMarket.PurchaseOffers.isNewPrice)
      sortedOffers = sortedOffers.filter(
        (offer) => offer.token_value_usd >= settingsMarket.PurchaseOffers.minNewPrice && offer.token_value_usd < settingsMarket.PurchaseOffers.maxNewPrice
      );
    if (settingsMarket.PurchaseOffers.isNewYield)
      sortedOffers = sortedOffers.filter(
        (offer) => offer.token_newYield * 100 >= settingsMarket.PurchaseOffers.minNewYield && offer.token_newYield * 100 < settingsMarket.PurchaseOffers.maxNewYield
      );
    if (settingsMarket.PurchaseOffers.isRented)
      sortedOffers = sortedOffers.filter(
        (offer) => offer.token_rented * 100 >= settingsMarket.PurchaseOffers.minRented && offer.token_rented * 100 <= settingsMarket.PurchaseOffers.maxRented
      );
    if (settingsMarket.PurchaseOffers.isLastPricingDays)
      sortedOffers = sortedOffers.filter(
        (offer) => offer.last_pricing_days >= settingsMarket.PurchaseOffers.minLastPricingDays && offer.last_pricing_days < settingsMarket.PurchaseOffers.maxLastPricingDays
      );
    if (settingsMarket.PurchaseOffers.isNbPricing)
      sortedOffers = sortedOffers.filter(
        (offer) => offer.nb_pricing >= settingsMarket.PurchaseOffers.minNbPricing && offer.nb_pricing < settingsMarket.PurchaseOffers.maxNbPricing
      );
    if (settingsMarket.PurchaseOffers.isNbOffers) {
      console.log('sortedOffers', sortedOffers[0]);
      sortedOffers = sortedOffers.filter(
        (offer) => offer.NbOffers >= settingsMarket.PurchaseOffers.minNbOffers && offer.nb_pricing < settingsMarket.PurchaseOffers.maxNbOffers
      );
    }
    const dateInf = new Date(settingsMarket.PurchaseOffers.dateInfRentStart);
    const dateSup = new Date(settingsMarket.PurchaseOffers.dateSupRentStart);
    const isDateInfValid = !Number.isNaN(dateInf.getTime());
    const isDateSupValid = !Number.isNaN(dateSup.getTime());
    if (settingsMarket.PurchaseOffers.isRentStart || settingsMarket.PurchaseOffers.isnotRentStart) {
      sortedOffers = sortedOffers.filter((offer) => {
        const offerDate = new Date(offer.rent_start).getTime();

        let keepOffer = false;

        // Si isRentStart est actif, on teste la condition "offerDate <= dateInf"
        if (settingsMarket.PurchaseOffers.isRentStart && isDateInfValid) {
          if (settingsMarket.PurchaseOffers.autoRentStart) keepOffer = keepOffer || offerDate <= new Date();
          else keepOffer = keepOffer || offerDate <= dateInf.getTime();
        }

        // Si isnotRentStart est actif, on teste la condition "offerDate >= dateSup"
        if (settingsMarket.PurchaseOffers.isnotRentStart && isDateSupValid) {
          if (settingsMarket.PurchaseOffers.autoRentStart) keepOffer = keepOffer || offerDate >= new Date();
          else keepOffer = keepOffer || offerDate >= dateSup.getTime();
        }

        // On ne garde l'offre que si "keepOffer" est vrai
        return keepOffer;
      });
    }
    if (settingsMarket.PurchaseOffers.isWhiteList) sortedOffers = sortedOffers.filter((offer) => offer.whiteList);
    setSortedOffers(sortedOffers);
  }, [offers, newOrModifyOffers, settingsMarket.PurchaseOffers]);

  // ------------------------------------------------------------
  // -- Calculate and filter 'offers' for WalletPourchasesOffersHead --
  // ------------------------------------------------------------
  useEffect(() => {
    // console.log("offers", offers[0]);
    if (!tokens) return;
    if (!offers) return;
    if (!settingsMarket.WalletPurchaseOffers) return;

    let sortedWalletOffersCount = 0;
    let sortedWalletOffers = [...tokens];

    sortedWalletOffers = sortedWalletOffers.filter((token) => token.whiteList);

    sortedWalletOffers.forEach((token, index) => {
      token.position = token.walletsAmount;
      token.positionWallet = token.walletAmount ? token.walletAmount : 0;
      token.rent_start = token.rentStartDate.date.slice(0, 10);
      token.token_rented = token.rentedUnits / token.totalUnits;

      const selectedWallet = settingsWallet.selectedWallet;
      token.offersList = offers.filter((offer) => offer.holder_address === selectedWallet && offer.shortName === token.shortName).map(({ token, ...rest }) => rest);

      token.offersList.forEach((offer) => {
        offer.editOffer = newOrModifyOffers.find(
          (newOffer) =>
            offer.holder_address === selectedWallet &&
            newOffer.holder_address === selectedWallet &&
            offer.shortName === newOffer.shortName &&
            offer.id_offer === newOffer.id_offer
        );
      });

      token.newOffersList = newOrModifyOffers.filter((offer) => offer.holder_address === selectedWallet && offer.shortName === token.shortName && offer.newOffer);

      token.handleAddOffers = handleAddOffers; // Référence directe à la fonction
      token.handleEditOffers = handleEditOffers; // Référence directe à la fonction
      token.handleRemoveOffers = handleRemoveOffers; // Référence directe à la fonction
    });

    // Filtrage des offres en fonctions des 3 boutons de filtrage
    if (settingsMarket?.WalletPurchaseOffers?.unselectedCity)
      sortedWalletOffers = sortedWalletOffers.filter((token) => {
        const city = token.fullName.split(',')[1].trim();
        return !settingsMarket.WalletPurchaseOffers.unselectedCity.includes(city);
      });
    if (settingsMarket?.WalletPurchaseOffers?.unselectedCoins) {
      sortedWalletOffers = sortedWalletOffers.map((token) => {
        return {
          ...token,
          offersList: token.offersList.filter((offer) => {
            // Vérifie si le token_to_pay_name de l'offre est dans la liste unselectedCoins
            return !settingsMarket.WalletPurchaseOffers.unselectedCoins.includes(offer.token_to_pay_name);
          }),
        };
      });
    }
    if (settingsMarket?.WalletPurchaseOffers?.unselectPropertyType)
      sortedWalletOffers = sortedWalletOffers.filter((token) => {
        const propertyType = token.propertyType;
        return !settingsMarket.WalletPurchaseOffers.unselectPropertyType.includes(propertyType);
      });

    // Filtrage des offres en fonctions des reglages
    if (settingsMarket?.WalletPurchaseOffers?.isPosition)
      sortedWalletOffers = sortedWalletOffers.filter(
        (token) => token.position >= settingsMarket.WalletPurchaseOffers.minPosition && token.position < settingsMarket.WalletPurchaseOffers.maxPosition
      );
    if (settingsMarket?.WalletPurchaseOffers?.isRealtPrice)
      sortedWalletOffers = sortedWalletOffers.filter(
        (token) => token.tokenPrice >= settingsMarket.WalletPurchaseOffers.minRealtPrice && token.tokenPrice < settingsMarket.WalletPurchaseOffers.maxRealtPrice
      );
    if (settingsMarket?.WalletPurchaseOffers?.isRealtYield)
      // console.log("sortedWallet",sortedWalletOffers[0].annualPercentageYield,settingsMarket.WalletPurchaseOffers.minRealtYield,settingsMarket.WalletPurchaseOffers.maxRealtYield)
      sortedWalletOffers = sortedWalletOffers.filter(
        (token) =>
          token.annualPercentageYield >= settingsMarket.WalletPurchaseOffers.minRealtYield &&
          token.annualPercentageYield < settingsMarket.WalletPurchaseOffers.maxRealtYield
      );
    if (settingsMarket?.WalletPurchaseOffers?.isRented) {
      sortedWalletOffers = sortedWalletOffers.filter((token) => {
        const tokenRentedPercent = token.token_rented * 100;
        const settings = settingsMarket.WalletPurchaseOffers;

        const meetsMinCondition = settings.minRented !== undefined ? tokenRentedPercent >= settings.minRented : true;
        const meetsMaxCondition = settings.maxRented !== undefined ? tokenRentedPercent <= settings.maxRented : true;

        return meetsMinCondition && meetsMaxCondition;
      });
    }
    const dateInf = new Date(settingsMarket.WalletPurchaseOffers?.dateInfRentStart) || new Date();
    const dateSup = new Date(settingsMarket.WalletPurchaseOffers?.dateSupRentStart) || new Date();
    const isDateInfValid = !Number.isNaN(dateInf.getTime());
    const isDateSupValid = !Number.isNaN(dateSup.getTime());
    if (settingsMarket.WalletPurchaseOffers.isRentStart || settingsMarket.WalletPurchaseOffers.isnotRentStart) {
      sortedWalletOffers = sortedWalletOffers.filter((offer) => {
        const offerDate = new Date(offer.rent_start).getTime();

        let keepOffer = false;

        // Si isRentStart est actif, on teste la condition "offerDate <= dateInf"
        if (settingsMarket.WalletPurchaseOffers.isRentStart && isDateInfValid) {
          if (settingsMarket.WalletPurchaseOffers.autoRentStart) keepOffer = keepOffer || offerDate <= new Date();
          else keepOffer = keepOffer || offerDate <= dateInf.getTime();
        }

        // Si isnotRentStart est actif, on teste la condition "offerDate >= dateSup"
        if (settingsMarket.WalletPurchaseOffers.isnotRentStart && isDateSupValid) {
          if (settingsMarket.WalletPurchaseOffers.autoRentStart) keepOffer = keepOffer || offerDate >= new Date();
          else keepOffer = keepOffer || offerDate >= dateSup.getTime();
        }

        // On ne garde l'offre que si "keepOffer" est vrai
        return keepOffer;
      });
    }
    sortedWalletOffers.forEach((token) => {
      token.nbOffers = token.offersList.length;
      sortedWalletOffersCount += token.offersList.length;
    });

    setSortedWalletOffersCount(sortedWalletOffersCount);
    setSortedWalletOffers(sortedWalletOffers);
  }, [tokens, offers, newOrModifyOffers, settingsMarket.WalletPurchaseOffers]);

  // useEffect(() => {
  // console.log("newOrModifyOffers", newOrModifyOffers);
  // }, [sortedWalletOffers]);

  const handleAddOffers = (id, shortName, newOffer) => {
    const token = tokens.filter((object) => object.shortName === shortName);

    if (token.length > 0) {
      token.forEach((object) => {
        if (object.gnosisContract !== newOffer.tokenToBuy) {
          newOffer.tokenToBuy = object.gnosisContract;
          newOffer.shortName = object.shortName;
        }
      });
    }

    setNewOrModifyOffers((prevOffers) => {
      if (id === 0) {
        // Ajouter une nouvelle offre avec un ID unique
        const newIdMax = prevOffers.length > 0 ? Math.max(...prevOffers.map((offer) => offer.id)) + 1 : 1;

        console.log('handleAddOffers (add)', newIdMax, shortName, newOffer);

        const newOfferObject = {
          id: newIdMax,
          shortName: shortName,
          newOffer: newOffer,
          holder_address: settingsWallet.selectedWallet,
        };

        // Mettre à jour idMax
        setIdMax(newIdMax + 1);

        return [...prevOffers, newOfferObject];
      } else {
        // Modifier une offre existante ou ajouter si elle n'existe pas
        const existingOffer = prevOffers.find((offer) => offer.id === id);

        if (existingOffer) {
          // Mise à jour de l'offre existante
          console.log('handleAddOffers (update)', id, shortName, newOffer);

          return prevOffers.map((offer) => (offer.id === id ? { ...offer, newOffer, shortName, holder_address: settingsWallet.selectedWallet } : offer));
        } else {
          // Ajouter une nouvelle offre si l'offre avec cet ID n'existe pas
          const newIdMax = prevOffers.length > 0 ? Math.max(...prevOffers.map((offer) => offer.id)) + 1 : 1;

          console.log('handleAddOffers (add as new)', newIdMax, shortName, newOffer);

          const newOfferObject = {
            id: newIdMax,
            shortName: shortName,
            newOffer: newOffer,
            holder_address: settingsWallet.selectedWallet,
          };

          // Mettre à jour idMax
          setIdMax(newIdMax + 1);

          return [...prevOffers, newOfferObject];
        }
      }
    });
  };

  const handleRemoveOffers = (id) => {
    setNewOrModifyOffers((prevOffers) => {
      // Vérifier si l'offre existe avant de la supprimer
      const existingOffer = prevOffers.find((offer) => offer.id === id);

      if (!existingOffer) {
        console.warn(`Offer with id ${id} does not exist.`);
        return prevOffers; // Aucun changement si l'offre n'existe pas
      }

      const updatedOffers = prevOffers.filter((offer) => offer.id !== id);
      console.log(`Offer with id ${id} has been removed.`);
      return updatedOffers;
    });
  };

  const handleEditOffers = (id, id_offer, shortName, editOffer) => {
    if (id === 0) {
      // Ajouter une nouvelle offre
      setNewOrModifyOffers((prevOffers) => {
        // Calcul de l'ID unique basé sur les IDs existants
        const newIdMax = prevOffers.length > 0 ? Math.max(...prevOffers.map((offer) => offer.id)) + 1 : 1;

        console.log('handleEditOffers (add)', newIdMax, shortName, editOffer);

        const newOffer = {
          id: newIdMax,
          id_offer: id_offer,
          shortName: shortName,
          editOffer: editOffer,
          holder_address: settingsWallet.selectedWallet,
        };

        // Mettre à jour idMax
        setIdMax(newIdMax + 1);

        return [...prevOffers, newOffer];
      });
    } else {
      // Vérifier si l'offre existe
      setNewOrModifyOffers((prevOffers) => {
        const existingOffer = prevOffers.find((offer) => offer.id === id);

        if (existingOffer) {
          // Mise à jour de l'offre existante
          console.log('handleEditOffers (update)', id, shortName, editOffer);
          return prevOffers.map((offer) => (offer.id === id ? { ...offer, editOffer, id_offer, shortName, holder_address: settingsWallet.selectedWallet } : offer));
        } else {
          // Ajout d'une nouvelle offre si elle n'existe pas
          const newIdMax = prevOffers.length > 0 ? Math.max(...prevOffers.map((offer) => offer.id)) + 1 : 1;
          console.log('handleEditOffers (add as new)', newIdMax, shortName, editOffer);

          const newOffer = {
            id: newIdMax,
            id_offer: id_offer,
            shortName: shortName,
            editOffer: editOffer,
            holder_address: settingsWallet.selectedWallet,
          };

          // Mettre à jour idMax
          setIdMax(newIdMax + 1);

          return [...prevOffers, newOffer];
        }
      });
    }
  };

  useEffect(() => {
    // Fonction utilitaire pour récupérer le nom de l'état
    const getStateName = (stateCode) => {
      const states = {
        AL: 'Alabama',
        AK: 'Alaska',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
      };
      return states[stateCode.toUpperCase()] || 'Unknown State';
    };

    if (!tokens) return;
    const _cityList = tokens
      .reduce((acc, token) => {
        const parts = token.fullName.split(',').map((part) => part.trim());
        let city, countryLabel;

        if (token.fullName.split(',').length === 3) {
          if (isCity) city = token.fullName.split(',')[1].trim();
          else city = getStateName(token.fullName.split(',')[2].trim().slice(0, 2));
        } else {
          if (isCity) city = token.fullName.split(',')[1].trim();
          else city = token.fullName.split(',')[3].trim();
        }

        let cityEntry = acc ? acc.find((entry) => entry.label === city) : null;
        if (!cityEntry) {
          cityEntry = { id: acc.length, count: 0, label: city, checked: true };
          acc.push(cityEntry);
        }
        cityEntry.count += 1;
        return acc;
      }, [])
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    setCityList(_cityList);
    // console.log("cityList Calculate");
    // console.table(_cityList);
  }, [tokens]);

  useEffect(() => {
    // console.log(offers[0]);
    if (!offers) return;

    const generateCoinsList = (offers, selectedWallet = null) => {
      return offers
        .reduce((acc, offer) => {
          const shouldInclude = !selectedWallet || offer.holder_address === selectedWallet;
          if (!shouldInclude) return acc;

          let coinEntry = acc.find((entry) => entry.id === offer.token_to_pay);
          if (!coinEntry) {
            coinEntry = {
              id: offer.token_to_pay,
              count: 0,
              label: offer.token_to_pay_name,
              img: offer.token_to_pay_img,
              checked: true,
            };
            acc.push(coinEntry);
          }
          coinEntry.count += 1;
          return acc;
        }, [])
        .sort((a, b) => b.count - a.count);
    };

    // Générer _coinsList et _walletCoinsList avec la fonction optimisée
    const _coinsList = generateCoinsList(offers);
    const _walletCoinsList = generateCoinsList(offers, settingsWallet?.selectedWallet);

    setCoinsList(_coinsList);
    setWalletCoinsList(_walletCoinsList);
    // console.log("coinsList Calculate");
    // console.table(_coinsList);
    // console.table(_walletCoinsList);
  }, [offers]);

  const exportToCSV_market_offers = (exportTable) => {
    // Colonnes à exclure
    const includedColumns = [
      'id_offer',
      'shortName',
      'fullName',
      'position',
      'positionWallet',
      'tokenPrice',
      'annualPercentageYield',
      'holder_address',
      'marketplaceLink',
      'city',
      'sc',
      'token_to_buy',
      'token_to_sell',
      'token_to_pay',
      'token_amount',
      'token_value',
      'token_value_usd',
      'token_to_pay_name',
      'token_newYield',
      'token_ratio_coin',
      'token_ratio',
      'rentedUnits',
      'totalUnits',
      'token_rented',
      'nb_pricing',
      'last_pricing_days',
      'rent_start',
      'whiteList',
    ];

    console.log('exportTable', exportTable[0]);
    console.log('exportTaincludedColumnsble', includedColumns);

    // Filtrer les clés pour générer les en-têtes (headers) et les trier selon `includedColumns`
    const headers = includedColumns.filter((key) => exportTable[0].hasOwnProperty(key));

    // Générer le contenu CSV
    const csvContent = [
      headers.join(';'), // Headers
      ...exportTable.map((row) =>
        headers
          .map((key) => (typeof row[key] === 'string' ? `"${row[key]}"` : row[key])) // Récupérer uniquement les colonnes nécessaires
          .join(';')
      ),
    ].join('\n');

    // Créer et télécharger le fichier CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'market_purchase_offers.csv';
    link.click();
  };

  if (!realTokens)
    return (
      <Paper key='realTokens' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
            Waiting Offers synchronisation ... Or no wallet define !
          </Typography>
        </Box>
      </Paper>
    );
  if (!holderWallets)
    return (
      <Paper key='holderWallets' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: red[700], borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 2, color: red[700] }} size='2rem' />
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: red[700] }}>
            No wallet define, go to Settings !
          </Typography>
        </Box>
      </Paper>
    );
  if (!holderWallets.length > 0)
    return (
      <Paper key='holderWallets' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: red[700], borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 2, color: red[700] }} size='2rem' />
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: red[700] }}>
            No wallet define, go to Settings !
          </Typography>
        </Box>
      </Paper>
    );
  if (!balanceWallets)
    return (
      <Paper key='balanceWallets' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
            Waiting Offers synchronisation ... Or no wallet define !
          </Typography>
        </Box>
      </Paper>
    );
  if (!tokens)
    return (
      <Paper key='tokens' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
            Waiting Offers synchronisation ... Or no wallet define !
          </Typography>
        </Box>
      </Paper>
    );
  if (!coinsList)
    return (
      <>
        <Paper key='coinsList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
          <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
            <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
              Waiting Offers synchronisation ... Or no wallet define !
            </Typography>
          </Box>
        </Paper>
      </>
    );
  if (!cityList)
    return (
      <Paper key='cityList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
            Waiting Offers synchronisation ... Or no wallet define !
          </Typography>
        </Box>
      </Paper>
    );
  if (!settingsMarket.PurchaseOffers)
    return (
      <Paper key='settingsMarket' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
            Waiting Offers synchronisation ... Or no wallet define !
          </Typography>
        </Box>
      </Paper>
    );
  if (!sortedOffers)
    return (
      <Paper key='sortedOffers' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
            Waiting Offers synchronisation ... Or no wallet define !
          </Typography>
        </Box>
      </Paper>
    );
  // if (!sortedWalletOffers)
  //   return (
  //     <Paper key='sortedOffers' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
  //       <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
  //         {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
  //         <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
  //           Waiting Offers synchronisation ... Or no wallet define !
  //         </Typography>
  //       </Box>
  //     </Paper>
  //   );
  if (!offersMarket.SwapCatOffersList)
    return (
      <Paper key='offersMarket.SwapCatOffersList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
            Waiting Offers synchronisation ... Or no wallet define !
          </Typography>
        </Box>
      </Paper>
    );
  if (!offersMarket.YamOffersList)
    return (
      <Paper key='offersMarket.YamOffersList' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
            Waiting Offers synchronisation ... Or no wallet define !
          </Typography>
        </Box>
      </Paper>
    );

  // console.log("sortedOffers",sortedOffers)
  // console.log("sortedWalletOffers",sortedWalletOffers)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      }}>
      <Box
        sx={{
          width: 'auto',
          // flexGrow: 0.1,
          border: 0,
          borderColor: 'primary.main',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center',
        }}>
        <StorefrontIcon
          color={allOffers ? 'primary' : 'disabled'}
          sx={{ ml: 1, mt: 0, fontSize: allOffers ? 40 : 25, cursor: allOffers ? 'default' : 'pointer' }}
          onClick={() => {
            setAllOffers(true);
            setWalletOffers(false);
            setHelpOffers(false);
            setShopping(false);
          }}
        />

        <Badge
          sx={{ ml: 1, mt: 2, fontSize: walletOffers ? 40 : 25, cursor: shopping ? 'default' : 'pointer' }}
          badgeContent={sortedWalletOffersCount}
          color='success'
          onClick={() => {
            setAllOffers(false);
            setWalletOffers(true);
            setHelpOffers(false);
            setShopping(false);
          }}>
          <AccountBalanceWalletOutlinedIcon
            color={walletOffers ? 'primary' : 'disabled'}
            sx={{ ml: 0, mt: 0, fontSize: walletOffers ? 40 : 25, cursor: walletOffers ? 'default' : 'pointer' }}
            onClick={() => {
              setAllOffers(false);
              setWalletOffers(true);
              setHelpOffers(false);
              setShopping(false);
            }}
          />
        </Badge>

        <InfoOutlinedIcon
          color={helpOffers ? 'primary' : 'disabled'}
          sx={{ ml: 1, mt: 2, fontSize: helpOffers ? 40 : 25, cursor: helpOffers ? 'default' : 'pointer' }}
          onClick={() => {
            setAllOffers(false);
            setWalletOffers(false);
            setHelpOffers(true);
            setShopping(false);
          }}
        />

        {/* <Badge
          sx={{ ml: 1, mt: 5, cursor: shopping ? 'default' : 'pointer' }}
          badgeContent={0}
          color='success'
          onClick={() => {
            setAllOffers(false);
            setWalletOffers(false);
            setHelpOffers(false);
            setShopping(true);
          }}>
          <ShoppingCartIcon
            color={shopping ? 'primary' : 'disabled'}
            sx={{ fontSize: shopping ? 40 : 25, cursor: shopping ? 'default' : 'pointer' }}
            onClick={() => {
              setAllOffers(false);
              setWalletOffers(false);
              setHelpOffers(false);
              setShopping(true);
            }}
          />
        </Badge> */}

        {allOffers && (
          <Tooltip title='Export Offers List as CSV file'>
            <Avatar
              alt={'CSV'}
              src={'/images/csv.png'}
              fontSize='small'
              sx={{ width: 40, height: 40, ml: 1, mt: 5, borderRadius: 0, cursor: 'pointer' }}
              onClick={() => exportToCSV_market_offers(sortedOffers)}
            />
          </Tooltip>
        )}
      </Box>

      {allOffers && (
        <Box
          sx={{
            flexGrow: 1,
            mx: 1,
            pr: isMatch ? 2 : 0,
            border: 0,
            borderColor: 'primary.main',
            borderRadius: 2,
          }}>
          <PurchasesOffersHead key='PurchasesOffersHead' offers={sortedOffers} cityList={cityList} coinsList={coinsList} />
        </Box>
      )}

      {walletOffers && (
        <Box
          sx={{
            flexGrow: 1,
            mx: 1,
            pr: isMatch ? 2 : 0,
            border: 0,
            borderColor: 'primary.main',
            borderRadius: 2,
          }}>
          <WalletPurchasesOffersHead
            key='WalletPurchasesOffersHead'
            offers={sortedWalletOffers}
            cityList={cityList}
            coinsList={walletCoinsList}
            newOrModifyOffers={newOrModifyOffers}
            setNewOrModifyOffers={setNewOrModifyOffers}
          />
        </Box>
      )}

      {helpOffers && (
        <Box
          sx={{
            flexGrow: 1,
            mx: 1,
            pr: isMatch ? 2 : 0,
            border: 0,
            borderColor: 'primary.main',
            borderRadius: 2,
          }}>
          helpOffers SOON !!!
          <br />
          {/* <TokensTableHead tokens={tokens} /> */}
        </Box>
      )}

      {/* {shopping && (
        <Box
          sx={{
            flexGrow: 1,
            mx: 1,
            pr: isMatch ? 2 : 0,
            border: 0,
            borderColor: 'primary.main',
            borderRadius: 2,
          }}>
          Shopping, It's an idea for future ... <br />
          <TokensTableHead tokens={tokens} />
        </Box>
      )} */}
    </Box>
  );
}

export default PurchasesOffers;
