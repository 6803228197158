import React, { useEffect} from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TITLE = "Pit's BI - Acceuil Français";

const Homepage = () => {
  const navigate = useNavigate();
const referalURL = 'https://realt.co/ref/Frederic%20Pitot/';

  useEffect(() => {
    document.title = TITLE;
  }, []);

  const handleReferalURLClick = () => {
    window.open(referalURL, '_blank');
  };

  return (
    <Container maxWidth='lg' style={{ marginTop: '2rem', textAlign: 'center' }}>
      <Typography variant='h2' gutterBottom>
        Bienvenue sur Pit's BI
      </Typography>

      <Typography variant='h5' gutterBottom>
        Investissez dans l'immobilier de manière simple, accessible et transparente.
      </Typography>

      <Box mt={4}>
        <Typography variant='h5' paragraph>
          Pit's BI est un outil de gestion de vos portefeuilles d'actifs que vous possédez à travers la société{' '}
          <a href='https://realt.co' target='_blank' rel='noopener noreferrer'>
            RealT
          </a>
          .{' '}
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography variant='h6' gutterBottom>
          Que propose Pit's BI comme fonctionalité ?
        </Typography>

        <ul style={{ textAlign: 'left', display: 'inline-block' }}>
          <li>
            <Typography variant='body1'>📊 Un tableau de bord simple et intuitif pour suivre vos investissements immobiliers.</Typography>
          </li>
          <li>
            <Typography variant='body1'>
              📈 Des statistiques détaillées sur tous les biens proposés par{' '}
              <a href='https://realt.co' target='_blank' rel='noopener noreferrer'>
                RealT
              </a>
              .
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>🔍 Un suivi précis de vos placements sur le RMM.</Typography>
          </li>
          <li>
            <Typography variant='body1'>🗺️ Une carte interactive indiquant la localisation de vos biens avec plusieurs options de filtrage.</Typography>
          </li>
          <li>
            <Typography variant='body1'>💱 Un outil de qualité pour l'achat et la vente sur le marché secondaire.</Typography>
          </li>
        </ul>
      </Box>

      <Box mt={4}>
        <Typography variant='h5' paragraph>
          RealT vous permet de devenir copropriétaire de biens immobiliers aux États-Unis en quelques clics.
        </Typography>

        <Typography variant='body1' paragraph>
          Avec des revenus locatifs versés directement en crypto-monnaie, vous bénéficiez d'un moyen moderne, sûr et rentable d'investir dans votre avenir.
        </Typography>

        <Typography variant='body1' paragraph>
          Que vous cherchiez à construire une retraite par capitalisation ou à diversifier votre portefeuille, RealT offre des opportunités adaptées à vos besoins.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant='h6' gutterBottom>
          Pourquoi choisir RealT ?
        </Typography>

        <ul style={{ textAlign: 'left', display: 'inline-block' }}>
          <li>
            <Typography variant='body1'>🏢 Accès à l'immobilier américain sans barrières.</Typography>
          </li>
          <li>
            <Typography variant='body1'>
              ♻ Revenus locatifs réguliers en crypto-monnaie <strong>distribué chaque lundi</strong>.
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>📈 Automatiser le réinvestissement de vos loyers dans de nouveaux biens.</Typography>
          </li>
          <li>
            <Typography variant='body1'>🔄 Utilisez le RMM pour faire du levier et maximiser vos investissements.</Typography>
          </li>
          <li>
            <Typography variant='body1'>🌐 Transparence totale avec des données actualisées.</Typography>
          </li>
          <li>
            <Typography variant='body1'>💰 Construisez une retraite sécurisée par capitalisation.</Typography>
          </li>
        </ul>
      </Box>

      <Box mt={6}>
        <Button variant='contained' color='primary' size='large' onClick={() => navigate('/stats')} style={{ marginRight: '1rem' }}>
          Explorer les statistiques
        </Button>

        <Button variant='outlined' color='secondary' size='large' onClick={handleReferalURLClick}>
          Créer un compte
        </Button>
      </Box>

      <Box mt={8}>
        <Typography variant='body2' color='textSecondary'>
          Le site est principalement en anglais, mais votre navigateur peut traduire automatiquement le contenu pour vous aider.
          <br />
          N'hésitez pas à explorer toutes les possibilités offertes par RealT.
        </Typography>
      </Box>
    </Container>
  );
};

export default Homepage;
