import { useTheme } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../../../../context/settings-context';
import { MyThemeContext } from '../../../../context/theme-context';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import TokensTableBody from './TokensTableBody';

function TokensTableHead({ tokens, chains }) {
  const { settingsDashboard, setSettingsDashboard, DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);
  const [selectedFullName, setSelectedFullName] = useState(() => {
    return sessionStorage.getItem('Dashboard_selectedFullName') || '';
  });

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  const [sortBy, setSortBy] = useState({ field: 'strRentStartDate', order: 'desc', type: 'text' });
  const theme = useTheme();

  const handleSort = (field, type) => {
    setSortBy((prevSortBy) => ({
      field,
      order: prevSortBy.field === field && prevSortBy.order === 'desc' ? 'asc' : 'desc',
      type,
    }));
  };

  useEffect(() => {
    sessionStorage.setItem('Dashboard_selectedFullName', selectedFullName);
  }, [selectedFullName]);

  let tokensList = tokens.map((t) => {
    let updatedToken = {
      ...t.token,
      amount: t.amount,
      chain: t.chain,
      propertyType_text: DEFAULT_PROPERTY_TYPE[t.token.propertyType].label,
      strRentStartDate: t.token.rentStartDate.date.slice(0, 10),
      strInitialLaunchDate: t.token.initialLaunchDate.date.slice(0, 10),
      rentedRatio: t.token.rentedUnits / t.token.totalUnits,
      rentReceive:
        settingsDashboard.selectedRentType === 'Day'
          ? t.amount * t.token.netRentDayPerToken
          : settingsDashboard.selectedRentType === 'Week'
          ? t.amount * t.token.netRentDayPerToken * 7
          : settingsDashboard.selectedRentType === 'Month'
          ? t.amount * t.token.netRentMonthPerToken
          : t.amount * t.token.netRentYearPerToken,
    };

    return updatedToken;
  });
  if (selectedFullName) tokensList = tokensList.filter((data) => data.fullName.toLowerCase().includes(selectedFullName.toLowerCase()));
  if (chains.update7) tokensList = tokensList.filter((t) => t.update7.length > 0);
  if (chains.update30) tokensList = tokensList.filter((t) => t.update30.length > 0);

  const sortedTokens = [...tokensList].sort((a, b) => {
    const order = sortBy.order === 'asc' ? 1 : -1;
    if (sortBy.type === 'number') return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
    if (sortBy.type === 'text') return order * a[sortBy.field].localeCompare(b[sortBy.field]);
    else return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(() => settingsDashboard.rowsPerPage || 25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);

    setSettingsDashboard((prevSettings) => ({
      ...prevSettings,
      rowsPerPage: event.target.value,
    }));
  };

  if (sortedTokens.length / rowsPerPage < page && page > 0) handleChangePage(page, page - 1);
  // console.log("TokensTableHead", sortedTokens.length, rowsPerPage, page);

  const selectedRentTypeChange = (newValue) => {
    setSettingsDashboard((prevSettings) => ({
      ...prevSettings,
      selectedRentType: newValue,
    }));
  };

  const selectedPropertyTypeChange = (newValue) => {
    setSettingsDashboard((prevSettings) => ({
      ...prevSettings,
      selectedPropertyType: newValue,
    }));
  };

  const handleResetSort = () => {
    // Logique de réinitialisation du tri
    setSortBy({ field: 'strRentStartDate', order: 'desc', type: 'text' });
  };

  // Function to convert tokensList to CSV
  const exportToCSV = () => {
    // Colonnes à exclure
    const excludedColumns = [
      'ethereumContract',
      'xDaiContract',
      'gnosisContract',
      'secondaryMarketplaces',
      'blockchainAddresses',
      'is_rmm',
      'historic',
      'pool',
      'update7',
      'update30',
      'chain',
      'rentStartDate',
      'lastUpdate',
      'coordinate',
      'id',
      'initialLaunchDate',
      'rent_start',
    ];

    // Filtrer les clés pour générer les en-têtes (headers) sans les colonnes exclues
    const headers = Object.keys(sortedTokens[0])
      .filter((key) => !excludedColumns.includes(key))
      .sort((a, b) => a.localeCompare(b));

    // Générer le contenu CSV
    const csvContent = [
      headers.join(';'), // Headers
      ...sortedTokens.map((row) =>
        headers
          .map((key) => (typeof row[key] === 'string' ? `"${row[key]}"` : row[key])) // Récupérer uniquement les colonnes nécessaires
          .join(';')
      ),
    ].join('\n');

    // Créer et télécharger le fichier CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'tokens_list.csv';
    link.click();
  };

  if (!tokens) return <></>;
  // console.log("TokensTableHead", sortedTokens[0]);

  return (
    <Box
      sx={{
        m: 1,
        p: 0,
        width: 1,
        border: 0,
        borderColor: 'primary.main',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box sx={{ width: 1, m: 0, mx: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* <Typography variant='h6' sx={{ m: 0, color: "primary.main" }}>
					Search
				</Typography> */}
        {/* <SearchIcon /> */}
        <TextField
          sx={{ mt: 0, ml: 1, minWidth: 200 }}
          label='Search in API fullName'
          onChange={(e) => setSelectedFullName(e.target.value)}
          variant='outlined'
          type='text'
          size='small'
          value={selectedFullName}
          onFocus={(e) => e.target.select()}
          InputProps={{
            // Utilisez "InputProps" avec un "P" majuscule
            endAdornment: selectedFullName ? ( // Condition modifiée pour une meilleure clarté
              <IconButton onClick={() => setSelectedFullName('')}>
                <ClearIcon />
              </IconButton>
            ) : null,
            style: {
              // Les styles doivent aussi être déplacés ici si spécifiques aux composants d'input
              textAlign: 'center',
              verticalAlign: 'center',
              padding: '0px 0px',
            },
          }}
        />
        <Chip
          sx={{ ml: 2 }}
          label='Ethereum'
          size='small'
          variant={chains.ethChain ? 'filled' : 'outlined'}
          color={chains.ethChain ? 'primary' : 'default'}
          onClick={() => chains.setEthChain(!chains.ethChain)}
        />
        <Chip
          sx={{ ml: 0.5 }}
          label='Gnosis'
          size='small'
          variant={chains.xdaiChain ? 'filled' : 'outlined'}
          color={chains.xdaiChain ? 'primary' : 'default'}
          onClick={() => chains.setXdaiChain(!chains.xdaiChain)}
        />
        <Chip
          sx={{ ml: 0.5 }}
          label='RMM'
          size='small'
          variant={chains.rmmChain ? 'filled' : 'outlined'}
          color={chains.rmmChain ? 'primary' : 'default'}
          onClick={() => chains.setRmmChain(!chains.rmmChain)}
        />
        <Chip
          sx={{ ml: 0.5 }}
          label='RMM v3'
          size='small'
          variant={chains.rmmv3Chain ? 'filled' : 'outlined'}
          color={chains.rmmv3Chain ? 'primary' : 'default'}
          onClick={() => chains.setRmmv3Chain(!chains.rmmv3Chain)}
        />
        <Chip
          sx={{ ml: 0.5 }}
          label='Pools'
          size='small'
          variant={chains.poolChain ? 'filled' : 'outlined'}
          color={chains.poolChain ? 'primary' : 'default'}
          onClick={() => chains.setPoolChain(!chains.poolChain)}
        />
        <Chip
          sx={{ ml: 0.5 }}
          label='7 days'
          size='small'
          variant={chains.update7 ? 'filled' : 'outlined'}
          color={chains.update7 ? 'primary' : 'default'}
          onClick={() => {
            chains.setUpdate7(!chains.update7);
            chains.setUpdate30(false);
          }}
        />

        <Chip
          sx={{ ml: 0.5 }}
          label='30 days'
          size='small'
          variant={chains.update30 ? 'filled' : 'outlined'}
          color={chains.update30 ? 'primary' : 'default'}
          onClick={() => {
            chains.setUpdate7(false);
            chains.setUpdate30(!chains.update30);
          }}
        />

        <TablePagination
          sx={{ ml: 'auto', borderRadius: 2 }}
          rowsPerPageOptions={[10, 25, 50, 75, 100, 250, 500, 750]}
          component='div'
          count={sortedTokens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Tooltip title='Export as CSV file'>
          <Avatar
            alt={'CSV'}
            src={'/images/csv.png'}
            fontSize='small'
            sx={{ width: 40, height: 40, ml: 1, borderRadius: 0, cursor: 'pointer' }}
            onClick={() => exportToCSV()}
          />
        </Tooltip>

        {/* <Button variant='contained' size='small' onClick={() => handleResetSort()} sx={{ ml: 1, borderRadius: 2 }}>
          Reset Sort
        </Button> */}
        {/* )} */}
      </Box>

      <Paper elevation={3} sx={{ mt: 0, width: 1, overflow: 'hidden' }}>
        <TableContainer sx={{ mt: 0 }}>
          <Table sx={{ width: 1, background: 'background.paper' }} aria-label='sticky table'>
            <TableHead>
              <TableRow
                accessKey='TokensTableHead'
                sx={{
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light,
                  '&:last-child th': { borderBottom: 0 },
                }}>
                <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1 }} colSpan={3}>
                  <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                    RealT
                  </Typography>
                </TableCell>
                {/* <TableCell align='center' sx={{ pl: 0, pr: 0, py: 0 }}>
								</TableCell> */}
                <TableCell align='left' sx={{ pl: 1, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('fullName', 'text')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      Token Name
                    </Typography>
                    {sortBy.field === 'fullName' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='left' sx={{ pl: 1, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('amount', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      Owned
                    </Typography>
                    {sortBy.field === 'amount' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>

                <TableCell align='left' sx={{ pl: 1, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('tokenPrice', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      Price
                    </Typography>
                    {sortBy.field === 'tokenPrice' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>

                <TableCell align='left' sx={{ pl: 1, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('annualPercentageYield', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      Yield
                    </Typography>
                    {sortBy.field === 'annualPercentageYield' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>

                {chains.update30 && (
                  <TableCell align='left' sx={{ pl: 1, pr: 0, py: 0, }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Previous Yield
                      </Typography>
                    </Box>
                  </TableCell>
                )}

                <TableCell align='left' sx={{ pl: 1, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('rentReceive', 'number')}>
                  <Tooltip title={'Rent Receive'}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      {/* <Typography variant='body2' style={{ fontWeight: "bold" }} 
											// onClick={() => handleSort("rentReceive", "number")}
											>
												Rent
											</Typography> */}
                      <Select
                        sx={{ ml: 0, minWidth: 50, zIndex: 15 }}
                        value={settingsDashboard.selectedRentType}
                        size='small'
                        onChange={(e) => selectedRentTypeChange(e.target.value)}>
                        {settingsDashboard.rentType.map((rent) => (
                          <MenuItem key={rent} value={rent}>
                            {rent}
                          </MenuItem>
                        ))}
                      </Select>
                      {sortBy.field === 'rentReceive' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </Tooltip>
                </TableCell>

                {!isMatch && (
                  <TableCell
                    align='left'
                    sx={{ pl: 1, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }}
                    // onClick={() => handleSort("totalTokens", "number")}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      {/* <Typography variant='body2' style={{ fontWeight: "bold" }}>
											type
										</Typography> */}
                      <Select
                        sx={{ mt: 0, ml: 0, minWidth: 125 }}
                        value={settingsDashboard.selectedPropertyType}
                        onChange={(e) => {
                          selectedPropertyTypeChange(e.target.value);
                        }}
                        size='small'>
                        {DEFAULT_PROPERTY_TYPE.filter((p) => !p.label.startsWith('Text_')).map((p) => (
                          <MenuItem key={p.index} value={p.index}>
                            {p.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* {sortBy.field === "totalTokens" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))} */}
                    </Box>
                  </TableCell>
                )}

                {!isMatch && (
                  <TableCell align='left' sx={{ pl: 1, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('totalTokens', 'number')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Nb RealToken
                      </Typography>
                      {sortBy.field === 'totalTokens' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>
                )}

                {!isMatch && (
                  <TableCell align='left' sx={{ pl: 1, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('totalInvestment', 'number')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Investment
                      </Typography>
                      {sortBy.field === 'totalInvestment' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>
                )}

                <TableCell align='left' sx={{ pl: 1, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('totalUnits', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      Rented Units
                    </Typography>
                    {sortBy.field === 'totalUnits' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>

                <TableCell align='left' sx={{ pl: 1, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('rentedRatio', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      Rented Ratio
                    </Typography>
                    {sortBy.field === 'rentedRatio' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>

                <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('strRentStartDate', 'text')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      Rent Start
                    </Typography>
                    {sortBy.field === 'strRentStartDate' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ p: 0 }}>
              {sortedTokens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((token) => (
                <TokensTableBody key={token.shortName} token={token} chains={chains} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default TokensTableHead;
