import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { SettingsContext } from '../context/settings-context';

import LoadingWebSite from '../components/Common/LoadingWebSite';
import Header from '../components/Header/Header';
import CountryHead from '../components/Traffic/CountryHead';

import { Box, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import VisitorsMapView from '../components/Traffic/VisitorsMapView';

const APIURL_PITSWAP = 'https://api.pitsbi.io/api/';
// const APIURL_PITSWAP = "https://pitswap-api.herokuapp.com/api/";
const TITLE = "Pit's BI - API Metrics";

function Traffic() {
  
  const { settingsLoaded } = useContext(SettingsContext);
  const { FRONT_END_NAME } = useContext(SettingsContext);
  const [traffic, setTraffic] = useState(null);

  useEffect(() => {
    document.title = TITLE;
  }, []);

  // --------------------------------------------------------
  // ---- Reccupération du traffic sur l'API pitswap     ----
  // --------------------------------------------------------

  const get_TrafficAPI = async () => {
    try {
      let request = APIURL_PITSWAP + 'get_clients_host';
      // console.log(request);
      let response = await fetch(request);
      if (response.ok) {
        let body = await response.json();
        // console.log("get_TrafficAPI", body);
        if (body.status === 'success') setTraffic(body.response);
        // openSnackbar(body.response, body.status);
      }
    } catch (error) {
      console.log('get_TrafficAPI - fetch error:', error);
    }
  };

  // -------------------------------------------------------
  // ---- Timer pour mise à jour des prix des tokens    ----
  // -------------------------------------------------------

  const TIMEOUT_REFRESH = 15 * 60 * 1000; // Timer pour le refresh du traffic
  const TIMEOUT_REFRESH_FAST = 10 * 1000; // Timer pour le refresh du traffic
  const [state, setState] = useState({ num: 0 });
  const counter = useRef(1);
  useEffect(() => {
    get_TrafficAPI();
    // console.log('get_TrafficAPI', traffic);

    counter.current += 1;
    let timer;
    if (traffic) timer = setTimeout(() => setState({ num: counter.current }), TIMEOUT_REFRESH_FAST);
    else timer = setTimeout(() => setState({ num: counter.current }), TIMEOUT_REFRESH);
    return () => clearTimeout(timer);
  }, [state]);

  // -------------------------------------------------------
const groupedData = useMemo(() => {
  if (!traffic) {
    // Retour par défaut si traffic est null ou undefined
    return {
      grouped_pitsbi: {},
      grouped_pitswap: {},
      grouped_total: {},
    };
  }
  const grouped_pitsbi = {};
  const grouped_pitswap = {};
  const grouped_total = {};
  if (traffic) {
    // console.log('keys pitsbi:', Object.keys(traffic.pitsbi.IPs));
    if (traffic?.pitsbi?.IPs)
      for (const client of Object.keys(traffic.pitsbi.IPs)) {
        const ip = traffic.pitsbi.IPs[client].detail;
        if (!ip) continue;
        if ('api' in ip) continue;

        if (ip && !grouped_pitsbi[ip.country]) {
          grouped_pitsbi[ip.country] = { code: ip.country_code, cities: {}, flag: ip.country_flag, nb_clients: 0, ips: [] };
        }
        if (ip && !grouped_pitsbi[ip.country].cities[ip.city]) {
          grouped_pitsbi[ip.country].cities[ip.city] = { name: ip.city, latitude: ip.latitude, longitude: ip.longitude, nb_clients: 0, ips: [] };
        }
        grouped_pitsbi[ip.country].nb_clients += 1;
        grouped_pitsbi[ip.country].ips.push(client);
        grouped_pitsbi[ip.country].cities[ip.city].nb_clients += 1;
        grouped_pitsbi[ip.country].cities[ip.city].ips.push(client);
        if (!grouped_total[ip.country]) {
          grouped_total[ip.country] = {
            name: ip.country,
            code: ip.country_code,
            cities: {},
            flag: ip.country_flag,
            nb_cities: 0,
            nb_clients: 0,
            ips: [],
            pitsbi: 0,
            pitswap: 0,
          };
        }

        if (!grouped_total[ip.country].cities[ip.city]) {
          grouped_total[ip.country].cities[ip.city] = {
            name: ip.city,
            latitude: ip.latitude,
            longitude: ip.longitude,
            nb_clients: 0,
            ips: [],
            pitsbi: 0,
            pitswap: 0,
          };
        }
        // console.log("client  pitsbi:", client);
        // console.table(grouped_total[ip.country].ips);
        grouped_total[ip.country].pitsbi += 1;
        grouped_total[ip.country].nb_clients += 1;
        grouped_total[ip.country].ips.push(client);
        grouped_total[ip.country].cities[ip.city].pitsbi += 1;
        grouped_total[ip.country].cities[ip.city].nb_clients += 1;
        grouped_total[ip.country].cities[ip.city].ips.push(client);
        grouped_total[ip.country].nb_cities = Object.keys(grouped_total[ip.country].cities).length;
        // console.log("client pitsbi:", client.toString(),grouped_total[ip.country].ips,ip.country);
      }

    // console.log("keys pitswap:",  Object.keys(traffic.pitswap.IPs));
    if (traffic?.pitswap?.IPs)
      for (const client of Object.keys(traffic.pitswap.IPs)) {
        const ip = traffic.pitswap.IPs[client].detail;
        if (!ip) continue;
        if ('api' in ip) continue;

        if (!grouped_pitswap[ip.country]) {
          grouped_pitswap[ip.country] = { code: ip.country_code, cities: {}, flag: ip.country_flag, nb_cities: 0, nb_clients: 0, ips: [] };
        }
        if (!grouped_pitswap[ip.country].cities[ip.city]) {
          grouped_pitswap[ip.country].cities[ip.city] = { latitude: ip.latitude, longitude: ip.longitude, nb_clients: 0, ips: [] };
        }
        grouped_pitswap[ip.country].nb_clients += 1;
        grouped_pitswap[ip.country].ips.push(client);
        grouped_pitswap[ip.country].cities[ip.city].nb_clients += 1;
        grouped_pitswap[ip.country].cities[ip.city].ips.push(client);
        if (!grouped_total[ip.country]) {
          grouped_total[ip.country] = {
            name: ip.country,
            code: ip.country_code,
            cities: {},
            flag: ip.country_flag,
            nb_clients: 0,
            ips: [],
            pitsbi: 0,
            pitswap: 0,
          };
        }
        if (!grouped_total[ip.country].cities[ip.city]) {
          grouped_total[ip.country].cities[ip.city] = {
            name: ip.city,
            latitude: ip.latitude,
            longitude: ip.longitude,
            nb_clients: 0,
            ips: [],
            pitsbi: 0,
            pitswap: 0,
          };
        }
        // console.table(grouped_total[ip.country].ips);
        grouped_total[ip.country].pitswap += 1;
        if (!grouped_total[ip.country].ips.includes(client.toString())) grouped_total[ip.country].nb_clients += 1;
        // else 			console.log("client on pitsbi to", client, ip.country, ip.city);
        grouped_total[ip.country].ips.push(client);
        grouped_total[ip.country].cities[ip.city].pitswap += 1;
        if (!grouped_total[ip.country].cities[ip.city].ips.includes(client.toString())) grouped_total[ip.country].cities[ip.city].nb_clients += 1;
        //else  console.log("client on pitsbi to", client,ip.city);
        grouped_total[ip.country].cities[ip.city].ips.push(client);
        grouped_total[ip.country].nb_cities = Object.keys(grouped_total[ip.country].cities).length;
        // console.log("client pitswap:", client.toString(),grouped_total[ip.country].ips,ip.country,grouped_total[ip.country].ips.includes(client.toString()));
      }

    // console.log("sorted_pitsbi");
    // console.table(sorted_pitsbi);
    // console.log("sorted_pitswap");
    // console.table(sorted_pitswap);
    console.log('traffic', traffic);
  }

  return { grouped_pitsbi, grouped_pitswap, grouped_total };
}, [traffic]);

const { grouped_pitsbi, grouped_pitswap, grouped_total } = groupedData;

  const nb_client_pitsbi = traffic?.pitsbi?.IPs ? Object.keys(traffic.pitsbi.IPs).length : 0;
  const nb_client_pitswap = traffic?.pitswap?.IPs ? Object.keys(traffic.pitswap.IPs).length : 0;
  const nb_client_common = traffic?.common?.IPs ? Object.keys(traffic.common.IPs).length : 0;
  let nb_client_total = 0;
  if (traffic)
    Object.keys(grouped_total).forEach((country) => {
      nb_client_total += grouped_total[country].nb_clients;
    });

    
  // const fileName = import.meta.url.split('/').pop();
  // // console.log(`[Render] ${import.meta.url}`);
  // console.log(`[Render] ${fileName}`);

  return (
    <Box>
      <Header />
      {!settingsLoaded && <LoadingWebSite />}
      {settingsLoaded && (
        <>
          <Box>
            {/* Introduction */}
            <Box>
              <Box sx={{ p: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {traffic && (
                  <Typography variant='h4' align='center' sx={{ mt: 1, px: 1 }} color={blue[700]}>
                    Rolling 7-Day Insights for <strong>{FRONT_END_NAME}</strong> and <strong>PitSwap</strong> websites
                  </Typography>
                )}
              </Box>

              {/* Chargement des metrics */}
              {!traffic && <LoadingWebSite />}

              {/* Calcul globaux */}
              {traffic && (
                <Box>
                  <Box sx={{ mb: 1, p: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ mb: 1, p: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant='h6' sx={{ ml: 0, textAlign: 'center' }} color={blue[700]}>
                        Number of clients: <strong>{nb_client_total}</strong>
                      </Typography>
                      <Typography variant='h6' sx={{ ml: 5, textAlign: 'center' }} color={blue[700]}>
                        Number of clients who visited this page: <strong>{nb_client_common}</strong>
                      </Typography>
                    </Box>
                    <Typography variant='h6' sx={{ m: 0 }} color={blue[700]}>
                      Number of clients on{' '}
                      <a href='https://realt.pitsbi.io' target='_blank'>
                        https://realt.pitsbi.io
                      </a>{' '}
                      : <strong>{nb_client_pitsbi}</strong> client(s), audience ratio:{' '}
                      <strong>{((100 * nb_client_pitsbi) / (nb_client_pitsbi + nb_client_pitswap)).toFixed(2)}%</strong>
                    </Typography>
                    <Typography variant='h6' sx={{ mb: 1 }} color={blue[700]}>
                      Number of clients on{' '}
                      <a href='https://realt.pitswap.site' target='_blank'>
                        https://realt.pitswap.site
                      </a>{' '}
                      : <strong>{nb_client_pitswap}</strong> client(s), audience ratio:{' '}
                      <strong>{((100 * nb_client_pitswap) / (nb_client_pitsbi + nb_client_pitswap)).toFixed(2)}%</strong>
                    </Typography>
                    {/* <Typography variant='h6' sx={{ m: 0 }} color={blue[700]}>
                    Number of clients on both sites: {nb_client_total} client(s), and {nb_client_pitsbi + nb_client_pitswap - nb_client_total} using the 2 websites.
                  </Typography> */}
                  </Box>

                  <Box sx={{ mb: 1, p: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <VisitorsMapView key={'VisitorsMapView-map'} countries={grouped_total} />

                    <CountryHead key={'CountryHead-table'} countries={grouped_total} />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Traffic;
