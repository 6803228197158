import React, { useContext, useState } from "react";
import { SettingsContext } from "../../../context/settings-context";
import { MyThemeContext } from "../../../context/theme-context";
import { useTheme } from "@mui/system";

import { Box, Paper, TableCell, TableRow, Typography, useMediaQuery, Avatar, Tooltip } from "@mui/material";
import { green, red, deepOrange } from "@mui/material/colors";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FormatedNumber from "../../Common/Formater/FormatedNumber";
import FormatedPrice from "../../Common/Formater/FormatedPrice";
import FormatedPercentage from "../../Common/Formater/FormatedPercentage";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

import SellWeb3Offer from "../BuyOffers/SellWeb3Offer";
import FormatedNumberDays from "../../Common/Formater/FormatedNumberDays";
import TokenInfos from "../../Common/TokenInfos";

function SalesOffersBody({ offer, index }) {
	const { settingsMarket, settingsWallet } = useContext(SettingsContext);
	const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const [timerId, setTimerId] = useState(null);
	const [open, setOpen] = useState(false);
	const [openInfo, setOpenInfo] = useState(false);
	const theme = useTheme();

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	if (!offer)
		return (
			<Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 3, borderColor: "secondary.main", borderRadius: 4 }}>
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
					<Typography variant='h5' sx={{ mx: 10, my: 1, color: "secondary.main" }}>
						Waiting offer synchronisation ...
					</Typography>
				</Box>
			</Paper>
		);

	const handleOfferClick = (offer) => {
		// if (offer.sc === "yam") window.open("https://yam.realtoken.network/offer/" + offer.id_offer, "_blank");
		if (offer.sc === "yam") window.open("https://yambyofferid.netlify.app?offerId=" + offer.id_offer, "_blank");
		if (offer.sc === "swapcat")
			window.open(
				"https://ipfs.io/ipfs/QmZ8wkYtFgGXpHpPGGjQAynS5m8jc4df9KwD4v1pV27yBK/offer.htm?o=" +
					offer.id_offer +
					"&c=100&BUY." +
					offer.token.symbol +
					".tokens.on.xdai.blockchain.DEX.OTC",
				"_blank"
			);
	};

	const cursorIcon = theme.palette.mode === "dark" ? "url(/images/AddShoppingCartIconDark.png),auto" : "url(/images/AddShoppingCartIcon.png),auto";

	return (
    <>
      {openInfo && <TokenInfos open={openInfo} setOpen={setOpenInfo} token={offer.token} />}
      {open && <SellWeb3Offer open={open} setOpen={setOpen} offer={offer} index={index} />}
      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell
          align='center'
          sx={{ pl: 0, pr: 0, py: 1, cursor: 'pointer' }}
          component='th'
          scope='row'
          onClick={() => window.open(offer.token.marketplaceLink, '_blank')}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <OpenInNewIcon color={'primary'} />
          </Box>
        </TableCell>

        <TableCell align='center' sx={{ pl: 0, pr: 0, py: 1, cursor: 'pointer' }} component='th' scope='row' onClick={() => setOpenInfo(true)}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <AddchartOutlinedIcon color={'primary'} />
          </Box>
        </TableCell>

        {/* <TableCell align='left' sx={{ pl: 0, pr: 0, py: 1, cursor: "pointer" }} onClick={() => handleOfferClick(offer)}> */}
        <TableCell align='left' sx={{ pl: 0, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <Tooltip title={'offer n°' + offer.id_offer} placement='top'>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Box component='img' alt='SmartContract' src={offer.sc === 'yam' ? '/images/yam.png' : '/images/swapcat.png'} sx={{ m: 0, width: 24, height: 24 }} />
            </Box>
          </Tooltip>
        </TableCell>

        <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <Tooltip title={offer.fullName} placement='top'>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
              <Typography variant='body2' sx={{ color: offer.whiteList ? green[400] : red[700] }}>
                {offer.shortName}
              </Typography>
              {settingsWallet?.selectedWallet === offer.holder_address && <WorkspacePremiumIcon sx={{ ml: 1 }} color={'primary'} />}
            </Box>
          </Tooltip>
        </TableCell>

        <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <Typography variant='body2' sx={{ color: offer.whiteList ? green[400] : red[700] }}>
            {offer.city}
          </Typography>
        </TableCell>

        {settingsMarket.SaleOffers.showPosition && (
          <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
            {offer.position === 0 || offer.position >= 0.0001 ? (
              <FormatedNumber value={offer.position} color={'primary.main'} digit={4} />
            ) : (
              <FormatedNumber value={offer.position} color={'primary.main'} digit={10} />
            )}
          </TableCell>
        )}

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <FormatedPrice value={offer.tokenPrice} color={'primary.main'} />
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <FormatedPercentage value={offer.annualPercentageYield / 100} color={'primary.main'} />
        </TableCell>

        <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
            <Tooltip title={offer.token_to_pay_name} placement='top'>
              <Avatar alt='coin' src={offer.token_to_pay_img} sx={{ m: 0, width: 20, height: 20 }} />
            </Tooltip>
          </Box>
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          {offer.token_amount === 0 || offer.token_amount >= 0.01 ? (
            <FormatedNumber value={offer.token_amount} color={'secondary.main'} />
          ) : (
            <FormatedNumber value={offer.token_amount} color={'secondary.main'} digit={10} />
          )}
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <FormatedPrice value={offer.token_value_usd} color={'secondary.main'} />
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <FormatedPercentage value={offer.token_newYield} color={'primary.main'} />
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <FormatedPercentage
            value={offer.token_ratio}
            color={
              offer.tokenPrice * (1 + offer.annualPercentageYield / 100) < offer.token_value_usd
                ? offer.tokenPrice * (1 + (2 * offer.annualPercentageYield) / 100) < offer.token_value_usd
                  ? red[700]
                  : deepOrange[200]
                : green[500]
            }
          />
        </TableCell>

        {settingsMarket.SaleOffers.showRented && (
          <Tooltip title={`Nb Unit(s) ${offer.token.rentedUnits}/${offer.token.totalUnits}`} placement='top'>
            <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
              <FormatedPercentage value={offer.token_rented} color={offer.token_rented < 1 ? red[500] : green[500]} />
            </TableCell>
          </Tooltip>
        )}

        {settingsMarket.SaleOffers.showLastPricingDays && (
          <>
            <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
              <FormatedNumberDays
                value={offer.last_pricing_days}
                color={offer.last_pricing_days > 365 * 2 ? red[500] : offer.last_pricing_days > 365 ? deepOrange[200] : green[500]}
              />
            </TableCell>
            <TableCell align='right' sx={{ pl: 1, pr: settingsMarket.SaleOffers.showRentStart ? 0 : 1, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
              <FormatedNumber
                value={offer.nb_pricing}
                // color={offer.last_pricing_days > 365 * 2 ? red[500] : offer.last_pricing_days > 365 ? deepOrange[200] : green[500]}
              />
            </TableCell>
          </>
        )}

        {settingsMarket.SaleOffers.showRentStart && (
          <TableCell align='right' sx={{ pl: 1, pr: 1, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
            <Typography variant='body2'>{offer.rent_start}</Typography>
          </TableCell>
        )}
      </TableRow>
    </>
  );
}

export default SalesOffersBody;
