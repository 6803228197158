import React, { useContext } from 'react';
// import axios from "axios";
import { SettingsContext } from '../../context/settings-context';

import { Box } from '@mui/material';

import BarPropertyYield from './RealTokensChart/BarPropertyYield';
import Header from './RealTokensChart/Header';
import HorizontalCollapsibleTokenList from './RealTokensChart/HorizontalCollapsibleTokenList';
import HorizontalCollapsibleTokenListYear from './RealTokensChart/HorizontalCollapsibleTokenListYear';
import LineChartTokenRent from './RealTokensChart/LineChartTokenRent';
import Menu01 from './RealTokensChart/Menu01';
import Menu02 from './RealTokensChart/Menu02';
import Menu03 from './RealTokensChart/Menu03';
import PiePropertyDiversity from './RealTokensChart/PiePropertyDiversity';
import PiePropertyType from './RealTokensChart/PiePropertyType';
import PieYearHolderCost from './RealTokensChart/PieYearHolderCost';
import TableInvestment from './RealTokensChart/TableInvestment';
import TableLevinswap from './RealTokensChart/TableLevinswap';
import TableProperties from './RealTokensChart/TableProperties';
import TablePropertyPrice from './RealTokensChart/TablePropertyPrice';
import TablePropertyVerifyCompanies from './RealTokensChart/TablePropertyVerifyCompanies';
import TableTokenValuation from './RealTokensChart/TableTokenValuation';
import TableValuationAssets from './RealTokensChart/TableValuationAssets';
import TableYearHolderCost from './RealTokensChart/TableYearHolderCost';
function RealTokens() {
  const { realTokens, holderWallets } = useContext(SettingsContext);

  if (!realTokens) return <></>;

  // console.log("holderWallets",holderWallets)

  const returnValue = (
    <Box id={'createPDF'} display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ width: 1, p: 2 }}>
      <Header />
      {realTokens && (
        <Box>
          <Menu01
            key='1.'
            number='1'
            title='RealT Investments'
            description={
              <>
                Overview of investments with RealT, featuring detailed tables and key metrics to understand the performance of the entire real estate portfolio at a glance.
                Assets Valuations are calculated since <strong>May 2023</strong>, historic of assets need to be completed!
              </>
            }
            content={
              <>
                <TableInvestment key={'TableInvestment'} />
                <TableProperties key={'TableProperties'} />
                {/* <TableTokens key={"TableTokens"}/> */}
                <TableValuationAssets key={'TableValuationAssets'} />
                <Menu02
                  key='1.1'
                  number='1.1'
                  displayed='block'
                  title='Evolution of Rents Collected by RealT Since the Beginning'
                  description={
                    <>
                      Calculation of weekly distributed rents, considering changes in yields and prices of RealTokens.
                      <ul>
                        <li>
                          <strong>Cumulative Rent (M$):</strong> Displays the total cumulative rents collected but with a dynamic evaluation based on changes in yields and
                          property prices. This reflects a more realistic projection of profitability over time.
                        </li>
                        <li>
                          <strong>Weekly Rent (k$):</strong> Shows the estimated weekly rents based on updated yields and evaluations. This provides a perspective on
                          potential weekly income based on current market conditions.
                        </li>
                      </ul>
                      Calculation of weekly distributed rents based solely on the initial prices and yields of RealTokens, without considering any rental vacancies.
                      <ul>
                        <li>
                          <strong>Cumulative Rent Initial (M$):</strong> Represents the total cumulative rents collected across all RealT properties since the beginning.
                          This curve visualizes the overall growth of collected rents over time.
                        </li>
                        <li>
                          <strong>Weekly Rent Initial (k$):</strong> Indicates the actual weekly rents collected. This curve highlights week-to-week variations and
                          identifies periods of increased or reduced activity.
                        </li>
                      </ul>
                    </>
                  }
                  content={
                    <>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                        {/* <ChartMonthInvestment key={"ChartMonthInvestment"} /> */}
                        <LineChartTokenRent key={'LineChartTokenRent'} />
                      </Box>
                    </>
                  }
                />
                <Menu02
                  key='1.2'
                  number='1.2'
                  title='Last 6 months Marketplace Activity'
                  description={
                    <>
                      Delve into detailed marketplace activity over the past six months. Explore an interactive timeline showcasing each property's launch and transactions.{' '}
                      <br />
                      Click to expand monthly summaries and view specific properties sold, gaining valuable insights into market trends and investment opportunities.
                    </>
                  }
                  content={
                    <>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                        {/* <ChartMonthInvestment key={"ChartMonthInvestment"} /> */}
                        <HorizontalCollapsibleTokenList key={'HorizontalCollapsibleTokenList'} />
                      </Box>
                    </>
                  }
                />
                <Menu02
                  key='1.3'
                  number='1.3'
                  title='Last 5 years Marketplace Activity'
                  description={
                    <>
                      Delve into detailed marketplace activity over the past five years. Explore an interactive timeline showcasing each property's launch and transactions.{' '}
                      <br />
                      Click to expand monthly summaries and view specific properties sold, gaining valuable insights into market trends and investment opportunities.
                    </>
                  }
                  content={
                    <>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                        {/* <ChartMonthInvestment key={"ChartMonthInvestment"} /> */}
                        <HorizontalCollapsibleTokenListYear key={'HorizontalCollapsibleTokenListYear'} />
                      </Box>
                    </>
                  }
                />
                <Menu02
                  key='1.4'
                  number='1.4'
                  title='Detailed Holder Costs'
                  description='Explore the specifics of annual and monthly holder costs with comprehensive charts and tables. Get insights into property management fees, maintenance costs, and more to understand the financial nuances of your RealT investments.'
                  content={
                    <>
                      <PieYearHolderCost key={'PieYearHolderCost'} />
                      <TableYearHolderCost key={'TableYearHolderCost'} />
                    </>
                  }
                />
                <Menu02
                  key='1.5'
                  number='1.5'
                  title='RealT Investments by Property Type '
                  description={
                    <>
                      Overview of investments with RealT, featuring detailed tables and key metrics to understand the performance by <strong>Property Type</strong> of the
                      entire real estate portfolio at a glance. <br />
                      Assets Valuations are calculated since <strong>May 2023</strong>, historic of assets need to be completed!
                    </>
                  }
                  content={
                    <>
                      <Menu03
                        key='1.5.1'
                        number='1.5.1'
                        title='Single Family '
                        content={
                          <>
                            <TableInvestment key={'TableInvestment'} defaultPropertyType={1} />
                            <TableProperties key={'TableProperties'} defaultPropertyType={1} />
                            {/* <TableTokens key={"TableTokens"} defaultPropertyType={1} /> */}
                            <TableValuationAssets key={'TableValuationAssets'} defaultPropertyType={1} />
                          </>
                        }
                        defaultExpand={false}
                      />

                      <Menu03
                        key='1.5.2'
                        number='1.5.2'
                        title='Multi Family '
                        content={
                          <>
                            <TableInvestment key={'TableInvestment'} defaultPropertyType={2} />
                            <TableProperties key={'TableProperties'} defaultPropertyType={2} />
                            {/* <TableTokens key={"TableTokens"} defaultPropertyType={2} /> */}
                            <TableValuationAssets key={'TableValuationAssets'} defaultPropertyType={2} />
                          </>
                        }
                        defaultExpand={false}
                      />
                      <Menu03
                        key='1.5.3'
                        number='1.5.3'
                        title='Duplex '
                        content={
                          <>
                            <TableInvestment key={'TableInvestment'} defaultPropertyType={3} />
                            <TableProperties key={'TablePropertieskey'} defaultPropertyType={3} />
                            {/* <TableTokens  key={"TableTokens"} defaultPropertyType={3} /> */}
                            <TableValuationAssets key={'TableValuationAssets'} defaultPropertyType={3} />
                          </>
                        }
                        defaultExpand={false}
                      />
                      <Menu03
                        key='1.5.4'
                        number='1.5.4'
                        title='SFR Portfolio '
                        content={
                          <>
                            <TableInvestment key={'TableInvestment'} defaultPropertyType={10} />
                            <TableProperties key={'TableProperties'} defaultPropertyType={10} />
                            {/* <TableTokens key={"TableTokens"} defaultPropertyType={10} /> */}
                            <TableValuationAssets key={'TableValuationAssets'} defaultPropertyType={10} />
                          </>
                        }
                        defaultExpand={false}
                      />
                      <Menu03
                        key='1.5.5'
                        number='1.5.5'
                        title='Quadplex'
                        content={
                          <>
                            <TableInvestment key={'TableInvestment'} defaultPropertyType={8} />
                            <TableProperties key={'TableProperties'} defaultPropertyType={8} />
                            {/* <TableTokens key={"TableTokens"} defaultPropertyType={8} /> */}
                            <TableValuationAssets key={'TableValuationAssets'} defaultPropertyType={8} />
                          </>
                        }
                        defaultExpand={false}
                      />
                    </>
                  }
                />
              </>
            }
          />
          <Menu01
            key='2.'
            number='2'
            title='Property Analysis'
            description='Dive deep into property analytics with pie charts and widgets that break down property types, investment returns, and other critical data points.'
            content={
              <>
                <Menu02
                  key='2.1'
                  number='2.1'
                  title='Detailed Property Composition Analysis'
                  description='Explore the nuances of realT property portfolio with comprehensive visualizations. Delve into the composition and diversity of property types and the distribution of investment returns with specialized pie charts and analytical widgets.'
                  content={
                    <>
                      <PiePropertyType key={'PiePropertyType'} />
                      <PiePropertyDiversity key={'PiePropertyDiversity'} />
                    </>
                  }
                />
                <Menu02
                  key='2.2'
                  number='2.2'
                  title='Yield Property Analysis'
                  // description='Dive deep into realT portfolio with our Interactive Property Analysis tool. Examine and understand the distribution, yields, and types of properties. Get a clear visual breakdown and gain valuable insights to make informed decisions.'
                  description='Examine and understand the distribution, yields, and types of properties. Get a clear visual breakdown and gain valuable insights to make informed decisions.'
                  content={
                    <>
                      <BarPropertyYield key={'BarPropertyYield'} />
                    </>
                  }
                />
                <Menu02
                  key='2.3'
                  number='2.3'
                  title='Prices Property Analysis'
                  description="Dive deep into the analysis of property prices. Gain understanding of the distribution and specific characteristics of each asset, whether it's an analysis per door, per square foot, or per square meter, to make well-informed decisions."
                  content={
                    <>
                      <TablePropertyPrice key={'TablePropertyPrice'} />
                    </>
                  }
                />
                <Menu02
                  key='2.4'
                  number='2.4'
                  title='Build Confidence: Verify RealT Companies'
                  description={
                    <>
                      Transparency is at the heart of your investments.
                      <br />
                      With this tool, you can easily verify the existence of companies created by RealT through the{' '}
                      <a href='https://icis.corp.delaware.gov/eCorp/EntitySearch/NameSearch.aspx' target='_blank' rel='noopener noreferrer'>
                        Delaware Division of Corporations
                      </a>{' '}
                      official website or{' '}
                      <a href='https://opencorporates.com/advanced-search/' target='_blank' rel='noopener noreferrer'>
                        OpenCorporates
                      </a>{' '}
                      website.
                      <br />
                      Simply enter the company name into our user-friendly interface. If another user has already searched for this company, our server will automatically
                      validate its legitimacy, retrieve its registration number, and provide you with direct links to official details. Choose clarity and confidence for
                      your financial decisions.
                    </>
                  }
                  content={
                    <>
                      <TablePropertyVerifyCompanies key={'TablePropertyVerifyCompanies'} />
                    </>
                  }
                />
              </>
            }
          />
          <Menu01
            key='3.'
            number='3'
            title='Valuation of RealToken Assets'
            description="Get a comprehensive overview of your RealT investments. Explore detailed tables and key metrics for a quick, informed glance at your real estate portfolio's performance."
            content={
              <>
                <TableTokenValuation key={'TableTokenValuation'} />
              </>
            }
          />

          {holderWallets && (
            <Menu01
              key='4.'
              number='4'
              title='Pools Levinswap'
              description={
                <>
                  List of Levinswap Pools where RealT deposits RealTokens and offers the rents of all other participants in these pools. <br />
                  The <strong>Rent and Yield</strong> are calculated per Year.
                  <br />
                  Levinswap Pools are refresh every hour. <br /> <br />
                  <em>
                    You can start by staking 1 or 2 dollars into a pool and wait until the following week to measure its effect. Don't rush, try it out first to learn how
                    it works.
                  </em>
                  <br />
                  <br />
                  <strong>Link to stake on pool : </strong>
                  <a href='https://app.levinswap.realtoken.community/#/add/ETH' target='_blank'>
                    https://app.levinswap.realtoken.community/#/add/ETH
                  </a>
                  <br />
                  <strong>Link to learn how to stake on Tuto Community : </strong>
                  <a href='https://community-realt.gitbook.io/tuto-community/defi-realt/dex-swap/levinswap' target='_blank'>
                    https://community-realt.gitbook.io/tuto-community/defi-realt/dex-swap/levinswap
                  </a>
                  <br />
                  <br />
                </>
              }
              content={
                <>
                  <Menu02
                    key='4.1'
                    number='4.1'
                    title='Pools Levinswap : USDC'
                    description={
                      <>
                        By default the list is sorted by <strong>"Pool Yield"</strong> to show you the most interesting first. You have just to set the number of{' '}
                        <strong>"Tokens Add"</strong> to view the new Yield.
                      </>
                    }
                    content={<>{<TableLevinswap key={'TableLevinswap-USD'} coinFilter={'USD'} />}</>}
                  />
                  <Menu02
                    key='4.2'
                    number='4.2'
                    title='Pools Levinswap : WETH'
                    description={
                      <>
                        By default the list is sorted by <strong>"Pool Yield"</strong> to show you the most interesting first. You have just to set the number of{' '}
                        <strong>"Tokens Add"</strong> to view the new Yield.
                      </>
                    }
                    content={<>{<TableLevinswap key={'TableLevinswap-ETH'} coinFilter={'Ether'} />}</>}
                  />
                  <Menu02
                    key='4.3'
                    number='4.3'
                    title='Pools Levinswap : LEVIN'
                    description={
                      <>
                        By default the list is sorted by <strong>"Pool Yield"</strong> to show you the most interesting first. You have just to set the number of{' '}
                        <strong>"Tokens Add"</strong> to view the new Yield.
                      </>
                    }
                    content={<>{<TableLevinswap key={'TableLevinswap-Levin'} coinFilter={'Levin'} />}</>}
                  />
                  {/* <Menu02 number='4.4' title='Pools Levinswap : All' description={<>Sort by <strong>"Pool Yield"</strong> to find the most interesting and adjust the number of <strong>"Tokens Add"</strong> to view the new Yield.</>} content={<>{<TableLevinswap  />}</>} /> */}
                </>
              }
            />
          )}
        </Box>
      )}
    </Box>
  );

  return returnValue;
}

export default RealTokens;
