import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from "../../context/settings-context";
import { MyThemeContext } from "../../context/theme-context";
import { useTheme } from "@mui/system";

import { green, red } from "@mui/material/colors";
import {
	Box,
	Button,
	Select,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	Tooltip,
	useMediaQuery,
	Chip,
	TextField,
	IconButton,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ClearIcon from "@mui/icons-material/Clear";

import CountryRow from "./CountryRow";
import CityHead from "./CityHead";

function CountryHead({ countries }) {
  const { settingsDashboard, setSettingsDashboard, DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
  const { settingsTrafic, setSettingsTrafic } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);
  const [selectedCountry, setSelectedCountry] = useState(() => {
    return sessionStorage.getItem('Dashboard_selectedFullName') || '';
  });

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  const [sortBy, setSortBy] = useState({ field: 'nb_clients', order: 'desc', type: 'number' });
  const theme = useTheme();

  const handleSort = (field, type) => {
    setSortBy((prevSortBy) => ({
      field,
      order: prevSortBy.field === field && prevSortBy.order === 'desc' ? 'asc' : 'desc',
      type,
    }));
  };

  const handleSelectCountry = (SelectCountry) => {
    setSelectedCountry(SelectCountry);
  };

  useEffect(() => {
    // console.log("Object.keys(countries)", Object.keys(countries).includes("France"));
    if (Object.keys(countries).includes('France'))
      if (countries) {
        // console.log("setSelectedCountry", countries["France"]);
        setSelectedCountry(countries['France']);
      } else if (countries) setSelectedCountry(countries[Object.keys(countries)[0]]);
  }, []);

  // Transforme en tableau itérable si nécessaire
  const iterableCountries = Object.values(countries);

  const sortedTokens = [...iterableCountries].sort((a, b) => {
    const order = sortBy.order === 'asc' ? 1 : -1;
    if (sortBy.type === 'number') return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
    if (sortBy.type === 'text') return order * a[sortBy.field].localeCompare(b[sortBy.field]);
    else return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
  });

  console.log('settingsTrafic', settingsTrafic);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(() => settingsTrafic.country_rowsPerPage || 25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);

    setSettingsTrafic((prevSettings) => ({
      ...prevSettings,
      country_rowsPerPage: event.target.value,
    }));
  };

  if (sortedTokens.length / rowsPerPage < page && page > 0) handleChangePage(page, page - 1);
  // console.log("TokensTableHead", sortedTokens.length, rowsPerPage, page);

  const handleResetSort = () => {
    // Logique de réinitialisation du tri
    setSortBy({ field: 'nb_clients', order: 'desc', type: 'number' });
  };

  // console.log("CountryHead");
  if (!countries) return <></>;
  if (!sortedTokens.length) return <></>;
  // console.log("sortedTokens",sortedTokens);

  const size_table = 850;
  return (
    <Box
      sx={{
        m: 1,
        p: 1,
        width: 1,
        border: 0,
        borderColor: 'primary.main',
        borderRadius: 4,
        display: 'flex',
        flexDirection: isMatch ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'top',
      }}>
      <Box>
        <Box sx={{ width: size_table, m: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TablePagination
            sx={{ my: 0, ml: 'auto', borderRadius: 2 }}
            rowsPerPageOptions={[10, 25, 50, 75, 100, 250, 500, 750]}
            component='div'
            count={sortedTokens.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>

        <Paper elevation={3} sx={{ mx: 1, width: size_table, overflow: 'hidden' }}>
          <TableContainer sx={{ mt: 0 }}>
            <Table sx={{ width: 1, background: 'background.paper' }} aria-label='sticky table'>
              <TableHead>
                <TableRow
                  accessKey='TokensTableHead'
                  sx={{
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light,
                    '&:last-child th': { borderBottom: 0 },
                  }}>
                  <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }} colSpan={1}>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      N°
                    </Typography>
                  </TableCell>
                  <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
                    <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                      Flag
                    </Typography>
                  </TableCell>
                  <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('name', 'text')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Countries
                      </Typography>
                      {sortBy.field === 'name' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('code', 'text')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Code
                      </Typography>
                      {sortBy.field === 'code' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('nb_cities', 'number')} colSpan={1}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Nb Cities
                      </Typography>
                      {sortBy.field === 'nb_cities' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('nb_clients', 'number')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Nb Clients
                      </Typography>
                      {sortBy.field === 'nb_clients' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('pitsbi', 'number')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Nb on pitsbi.io
                      </Typography>
                      {sortBy.field === 'pitsbi' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('pitswap', 'number')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Nb on pitswap.site
                      </Typography>
                      {sortBy.field === 'pitswap' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('pitswap', 'number')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Cities
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{ p: 0 }}>
                {sortedTokens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((country, index) => (
                  <CountryRow key={index} country={country} order={index + 1} handleSelectCountry={handleSelectCountry} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <Box>{selectedCountry && <CityHead country={selectedCountry} />}</Box>
    </Box>
  );
}

export default CountryHead;
