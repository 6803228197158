import { useTheme } from '@mui/system';
import React, { useContext } from 'react';
import { SettingsContext } from '../../../context/settings-context';

import LoadingWebSite from '../LoadingWebSite';

import { Avatar, Box, IconButton, Typography } from '@mui/material';

function GnosisCard() {
  const { settingsLoaded } = useContext(SettingsContext);
  const theme = useTheme();

  const referalURL_realT = 'https://realt.co/ref/Frederic%20Pitot/';
  const referalURL_BienPreter = 'https://www.bienpreter.com/parrain/U61F6DDA125AA3';
  const referalURL_MtPelerin = 'https://www.mtpelerin.com/buy-xdai?bsc=EUR&sdc=EUR&rfr=CvZZWr53';
  const referalURL_GnosisCard = 'https://gnosispay.com/nonoginvite?rcc=3dfc86146523&utm_source=referral&utm_campaign=cm0woym5c008m9ydfex1ibtgf';

  const RealTLogo = theme.palette.mode === 'dark' ? '/images/RealT_Logo_dark.svg' : '/images/RealT_Logo.svg';
  const BienPreterLogo = theme.palette.mode === 'dark' ? '/images/bienpreter_dark.png' : '/images/bienpreter.png';
  const MtPelerinLogo = theme.palette.mode === 'dark' ? '/images/mtpelerin.svg' : '/images/mtpelerin.svg';
  const GnosisCardLogo = theme.palette.mode === 'dark' ? '/images/gnosiscard.png' : '/images/gnosiscard.png';
  const CommunityLogo = theme.palette.mode === 'dark' ? '/images/realtcommunity_dark.png' : '/images/realtcommunity.png';
  const RentTrackerLogo = theme.palette.mode === 'dark' ? '/images/renttraker_dark.png' : '/images/renttraker.png';

  const handleReferalURLClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      {!settingsLoaded && <LoadingWebSite />}
      {settingsLoaded && (
        <Box sx={{ m: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton onClick={() => handleReferalURLClick(referalURL_GnosisCard)}>
            <Avatar
              alt={'GnosisCardLogo'}
              src={GnosisCardLogo}
              fontSize='small'
              sx={{ width: 200, height: 80 }}
              imgProps={{ style: { objectFit: 'contain', height: '65%', width: 'auto' } }}
            />
          </IconButton>
          <Typography variant='h5' sx={{ fontWeight: 'bold', mb: 1, color: 'primary.main' }}>
            Get <strong>Gnosis Card</strong> free !
          </Typography>
        </Box>
      )}
    </>
  );
}

export default GnosisCard;
