import React, { useState } from "react";

import {} from "@mui/system";
import { Box, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import TablePropertyPriceSectorsMapRow from "./TablePropertyPriceSectorsMapRow";

function TablePropertyPriceSectorsMap({ tokensMap, parent, selectedCalculType }) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const [sortBy, setSortBy] = useState({ field: "doorsPrice", order: "desc", type: "number" });

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleSort = (field, type) => {
		setSortBy((prevSortBy) => ({
			field,
			order: prevSortBy.field === field && prevSortBy.order === "asc" ? "desc" : "asc",
			type,
		}));
	};

	tokensMap[0].tokens.forEach((x) => {
		// console.log("x", x);
		x.doorsPrice = (x.underlyingAssetPrice + x.initialMaintenanceReserve + x.renovationReserve) / x.totalUnits;
		x.sqftPrice = (x.underlyingAssetPrice + x.initialMaintenanceReserve + x.renovationReserve) / x.squareFeet;
		x.m2Price = (x.underlyingAssetPrice + x.initialMaintenanceReserve + x.renovationReserve) / x.squareFeet / 0.092903;
		return x;
	});

	const sortedTokens = [...Object.values(tokensMap[0].tokens)].sort((a, b) => {
		const order = sortBy.order === "asc" ? 1 : -1;
		if (sortBy.type === "number") return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
		if (sortBy.type === "text") return order * a[sortBy.field].localeCompare(b[sortBy.field]);
		else return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
	});

	// console.log("SectorsMap", sortedTokens);

	return (
		<Paper elevation={6} sx={{ m: 1, p: 1, border: 0, borderColor: "primary.main", borderRadius: 4 }}>
			<Box
				sx={{
					m: 1,
					p: 0,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50, 75, 100]}
					component='div'
					count={tokensMap[0].tokens.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 500 }} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
									RealT
								</TableCell>
								{/* <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}></TableCell> */}
								<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }} colSpan={2}>
									Address
								</TableCell>
								<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
									Acquisition Date
								</TableCell>
								<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
									Investment
								</TableCell>
								<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
									Type
								</TableCell>
								<TableCell
									align='right'
									sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
									onClick={() => handleSort("doorsPrice", "number")}
								>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
										Investment/Door
										{sortBy.field === "doorsPrice" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))}
									</Box>
								</TableCell>
								<TableCell
									align='right'
									sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
									onClick={() => handleSort("sqftPrice", "number")}
								>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
										Investment/sqft
										{sortBy.field === "sqftPrice" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))}
									</Box>
								</TableCell>
								<TableCell
									align='right'
									sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
									onClick={() => handleSort("m2Price", "number")}
								>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
										Investment/m2
										{sortBy.field === "m2Price" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))}
									</Box>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{sortedTokens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((token) => (
								<TablePropertyPriceSectorsMapRow token={token} calculType={selectedCalculType} parent={parent} key={token.label} />
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Paper>
	);
}

export default TablePropertyPriceSectorsMap;
