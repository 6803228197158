import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from "../../context/settings-context";
import { MyThemeContext } from "../../context/theme-context";
import { useTheme } from "@mui/system";

import { green, red } from "@mui/material/colors";
import {
	Box,
	Button,
	Select,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	Tooltip,
	useMediaQuery,
	Chip,
	TextField,
	IconButton,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TokensTableBody from "./TokensTableBody";
import ClearIcon from "@mui/icons-material/Clear";

function TokensTableHead({ tokens, chains }) {
	const { settingsDashboard, setSettingsDashboard, DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const { themeContext } = useContext(MyThemeContext);
	const [selectedFullName, setSelectedFullName] = useState(() => {
		return sessionStorage.getItem("Dashboard_selectedFullName") || "";
	});

	const isMatch = useMediaQuery(themeContext.breakpoints.down("lg"));

	const [sortBy, setSortBy] = useState({ field: "strRentStartDate", order: "desc", type: "text" });
	const theme = useTheme();

	const handleSort = (field, type) => {
		setSortBy((prevSortBy) => ({
			field,
			order: prevSortBy.field === field && prevSortBy.order === "desc" ? "asc" : "desc",
			type,
		}));
	};

	useEffect(() => {
		sessionStorage.setItem("Dashboard_selectedFullName", selectedFullName);
	}, [selectedFullName]);

	let tokensList = tokens.map((t) => {
		let updatedToken = {
			...t,
			strRentStartDate: t.rentStartDate.date.slice(0, 10),
			rentedRatio: t.rentedUnits / t.totalUnits,
			initPrice: t.historic.init_price,
			initYield: t.historic.init_yield,
		};

		return updatedToken;
	});

	if (selectedFullName) tokensList = tokensList.filter((data) => data.fullName.toLowerCase().includes(selectedFullName.toLowerCase()));
	if (chains.update7) tokensList = tokensList.filter((t) => t.update7.length > 0);
	if (chains.update30) tokensList = tokensList.filter((t) => t.update30.length > 0);

	const sortedTokens = [...tokensList].sort((a, b) => {
		const order = sortBy.order === "asc" ? 1 : -1;
		if (sortBy.type === "number") return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
		if (sortBy.type === "text") return order * a[sortBy.field].localeCompare(b[sortBy.field]);
		else return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
	});

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	if (sortedTokens.length / rowsPerPage < page && page > 0) handleChangePage(page, page - 1);

	const handleResetSort = () => {
		// Logique de réinitialisation du tri
		setSortBy({ field: "strRentStartDate", order: "desc", type: "text" });
	};

	const selectedRentTypeChange = (newValue) => {
		setSettingsDashboard((prevSettings) => ({
			...prevSettings,
			selectedRentType: newValue,
		}));
	};

	const selectedPropertyTypeChange = (newValue) => {
		setSettingsDashboard((prevSettings) => ({
			...prevSettings,
			selectedPropertyType: newValue,
		}));
	};

	if (!tokens) return <></>;
	// console.log("sortedTokens", sortedTokens);

	return (
		<Box
			sx={{
				m: 1,
				p: 0,
				border: 0,
				borderColor: "primary.main",
				borderRadius: 4,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box sx={{ width: 1, m: 0, mx: "auto", width: "900px", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<TextField
					sx={{ my: 0.5, ml: 1, minWidth: 300 }}
					label="Recherche avec le fullName de l'API"
					onChange={(e) => setSelectedFullName(e.target.value)}
					variant='outlined'
					type='text'
					size='small'
					value={selectedFullName}
					onFocus={(e) => e.target.select()}
					InputProps={{
						// Utilisez "InputProps" avec un "P" majuscule
						endAdornment: selectedFullName ? ( // Condition modifiée pour une meilleure clarté
							<IconButton onClick={() => setSelectedFullName("")}>
								<ClearIcon />
							</IconButton>
						) : null,
						style: {
							// Les styles doivent aussi être déplacés ici si spécifiques aux composants d'input
							textAlign: "center",
							verticalAlign: "center",
							padding: "0px 0px",
						},
					}}
				/>
				<TablePagination
					sx={{
						ml: "auto",
						borderRadius: 2,
						backgroundColor: "background.paper",
						p: 1,
						"& .MuiTablePagination-toolbar": {
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						},
						"& .MuiTablePagination-actions": {
							color: "primary.main",
						},
						"& .MuiIconButton-root": {
							color: "primary.main",
							"&:hover": {
								backgroundColor: "rgba(0, 0, 0, 0.1)",
							},
						},
						"& .MuiInputBase-root": {
							border: "1px solid",
							borderColor: "divider",
							borderRadius: "4px",
							padding: "0 8px",
							"&:hover": {
								borderColor: "primary.main",
							},
						},
					}}
					rowsPerPageOptions={[10, 25, 50, 75, 100, 250, 500, 750]}
					component='div'
					count={sortedTokens.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Lignes par page" // Personnalisation du texte
					labelDisplayedRows={({ from, to, count }) => `Affichage de ${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`} // Personnalisation du texte affiché
				/>
			</Box>

			<Paper elevation={3} sx={{ mt: 0, width: 1, heigh: "calc(100vh-150px)" }}>
				<TableContainer sx={{ mt: 0 }}>
					<Table sx={{ width: 1, background: "background.paper" }} aria-label='sticky table'>
						<TableHead>
							<TableRow
								accessKey='TokensTableHead'
								sx={{
									borderBottom: `2px solid ${theme.palette.primary.main}`,
									backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.main : theme.palette.primary.light,
									"&:last-child th": { borderBottom: 0 },
								}}
							>
								<TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }} colSpan={2}>
									<Typography variant='body2' style={{ fontWeight: "bold" }}>
										RealT
									</Typography>
								</TableCell>

								<TableCell
									align='center'
									sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
									onClick={() => handleSort("fullName", "text")}
								>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											Nom
										</Typography>
										{sortBy.field === "fullName" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))}
									</Box>
								</TableCell>

								<TableCell
									align='center'
									sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
									onClick={() => handleSort("initPrice", "number")}
								>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											Prix initial
										</Typography>
										{sortBy.field === "initPrice" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))}
									</Box>
								</TableCell>

								<TableCell
									align='center'
									sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
									onClick={() => handleSort("tokenPrice", "number")}
								>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											Prix Actuel
										</Typography>
										{sortBy.field === "tokenPrice" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))}
									</Box>
								</TableCell>

								<TableCell
									align='left'
									sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
									onClick={() => handleSort("initYield", "number")}
								>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											Yield initial
										</Typography>
										{sortBy.field === "initYield" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))}
									</Box>
								</TableCell>

								<TableCell
									align='center'
									sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
									onClick={() => handleSort("annualPercentageYield", "number")}
								>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											Yield Actuel
										</Typography>
										{sortBy.field === "annualPercentageYield" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))}
									</Box>
								</TableCell>

								<TableCell
									align='center'
									sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
									onClick={() => handleSort("rentedRatio", "number")}
									colSpan={2}
								>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											Etat locatif
										</Typography>
										{sortBy.field === "rentedRatio" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))}
									</Box>
								</TableCell>

								<TableCell
									align='center'
									sx={{ pl: 1, pr: 1, py: 1, "&:hover": { cursor: "pointer" } }}
									onClick={() => handleSort("strRentStartDate", "text")}
								>
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Typography variant='body2' style={{ fontWeight: "bold" }}>
											Date de départ
										</Typography>
										{sortBy.field === "strRentStartDate" &&
											(sortBy.order === "asc" ? (
												<ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											) : (
												<ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
											))}
									</Box>
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody sx={{ p: 0 }}>
							{sortedTokens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((token) => (
								// {sortedTokens.map((token) => (
								<TokensTableBody key={token.id} token={token} chains={chains} />
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Box>
	);
}

export default TokensTableHead;
