import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n"; // Importer le fichier de configuration d'i18next

import { MyThemeProvider } from "./context/theme-context";
import { ApiCoinGeckoProvider } from "./context/apiCoinGecko-context";
import { SettingsProvider } from "./context/settings-context";
import { RealTokensProvider } from "./context/realTokens-context";
import { SnackbarProvider } from "./context/customsnackbar-context";
import { Web3Provider } from "./context/web3-context.jsx";
import ErrorBoundary from "./components/Common/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));

const logBuffer = [];
const MAX_LOGS = 100;

// Surcharge des méthodes console
["log", "warn", "error", "info"].forEach((method) => {
	const originalMethod = console[method];
	console[method] = (...args) => {
		logBuffer.push({ type: method, message: args.join(" "), timestamp: new Date() });
		if (logBuffer.length > MAX_LOGS) logBuffer.shift();
		originalMethod.apply(console, args);
	};
});

root.render(
	<ErrorBoundary>
		<React.StrictMode>
			<SnackbarProvider>
				<SettingsProvider>
					<MyThemeProvider>
						<RealTokensProvider>
							<ApiCoinGeckoProvider>
								<Web3Provider>
									<App />
								</Web3Provider>
							</ApiCoinGeckoProvider>
						</RealTokensProvider>
					</MyThemeProvider>
				</SettingsProvider>
			</SnackbarProvider>
		</React.StrictMode>
	</ErrorBoundary>
);
