import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { SettingsContext } from "../../../context/settings-context";
import { Select, MenuItem, Box, Typography, Paper } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

// Enregistrer les éléments nécessaires pour le graphique en secteurs
Chart.register(ArcElement, Tooltip, Legend);

function PiePropertyType() {
	const { realTokens, holderWallets, balanceWallets, DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
	const { settingsDashboard, setSettingsDashboard } = useContext(SettingsContext);
	const [tokens, setTokens] = useState(null);
	const [propertyCount, setPropertyCount] = useState(null);
	const [chartData, setChartData] = useState(null);
	const selectedRentType = "Year";

	// Accéder au thème MUI
	const theme = useTheme();


	useEffect(() => {
		if (realTokens) {
			let tokens = [
				...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType !== "equity_token"),
			];
			let tokensBalance = [{ label: "PiePropertyType", chain: "total", count: 0, rent: 0, total: 0, yield: 0, tokens: [] }];

			if (balanceWallets) {
				tokensBalance.forEach((tokenBalance) => {
          if (holderWallets)
            holderWallets
              .filter((holderWallet) => holderWallet.checked)
              .forEach((holderWallet) => {
                for (const wallet of Object.keys(balanceWallets)) {
                  if (holderWallet.address === wallet) {
                    for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                      if (tokenBalance.chain === 'eth' || tokenBalance.chain === 'total') {
                        if (chain === 'eth') {
                          const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                          if (tokensList)
                            tokensList.forEach((token) => {
                              const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
                              if (realToken) {
                                if (selectedRentType === 'Day') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
                                if (selectedRentType === 'Week') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
                                if (selectedRentType === 'Month') tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
                                if (selectedRentType === 'Year') tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
                                tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
                                tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
                                tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                if (existingTokenIndex >= 0) {
                                  // console.log("Update eth", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, token.amount);
                                  tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
                                  tokenBalance.tokens[existingTokenIndex].chain.push({
                                    id: 'eth',
                                    amount: parseFloat(token.amount),
                                    wallet: wallet,
                                  });
                                } else {
                                  // If the token doesn't exist, push a new entry
                                  tokenBalance.tokens.push({
                                    id: realToken.id,
                                    token: realToken,
                                    amount: parseFloat(token.amount),
                                    chain: [{ id: 'eth', amount: parseFloat(token.amount), wallet: wallet }],
                                  });
                                }
                              }
                              // }
                            });
                        }
                      }

                      if (tokenBalance.chain === 'xdai' || tokenBalance.chain === 'total') {
                        if (chain === 'xdai') {
                          const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                          if (tokensList)
                            tokensList.forEach((token) => {
                              const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
                              if (realToken) {
                                // if (Date.now() > getNextRonday(realToken)) {
                                if (selectedRentType === 'Day') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
                                if (selectedRentType === 'Week') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
                                if (selectedRentType === 'Month') tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
                                if (selectedRentType === 'Year') tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
                                tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
                                tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
                                tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                if (existingTokenIndex >= 0) {
                                  tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
                                  tokenBalance.tokens[existingTokenIndex].chain.push({
                                    id: 'xdai',
                                    amount: parseFloat(token.amount),
                                    wallet: wallet,
                                  });
                                } else {
                                  tokenBalance.tokens.push({
                                    id: realToken.id,
                                    token: realToken,
                                    amount: parseFloat(token.amount),
                                    chain: [{ id: 'xdai', amount: parseFloat(token.amount), wallet: wallet }],
                                  });
                                }
                              }
                              // }
                            });
                        }
                      }

                      if (tokenBalance.chain === 'rmm' || tokenBalance.chain === 'total') {
                        if (chain === 'rmm') {
                          const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                          if (tokensList)
                            tokensList.forEach((token) => {
                              const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
                              if (token.reserve.underlyingAsset !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
                                const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
                                if (realToken) {
                                  // if (Date.now() > getNextRonday(realToken)) {
                                  if (selectedRentType === 'Day') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
                                  if (selectedRentType === 'Week') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
                                  if (selectedRentType === 'Month') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
                                  if (selectedRentType === 'Year') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                                  tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                                  tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
                                  tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                  let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                  if (existingTokenIndex >= 0) {
                                    // console.log("Update rmm", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, rmmBalance);
                                    tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
                                    tokenBalance.tokens[existingTokenIndex].chain.push({
                                      id: 'rmm',
                                      amount: parseFloat(rmmBalance),
                                      wallet: wallet,
                                    });
                                  } else {
                                    // If the token doesn't exist, push a new entry
                                    tokenBalance.tokens.push({
                                      id: realToken.id,
                                      token: realToken,
                                      amount: parseFloat(rmmBalance),
                                      chain: [{ id: 'rmm', amount: parseFloat(rmmBalance), wallet: wallet }],
                                    });
                                  }
                                  // }
                                }
                              }
                            });
                        }
                      }

                      if (tokenBalance.chain === 'rmmv3' || tokenBalance.chain === 'total') {
                        if (chain === 'rmmv3') {
                          const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                          if (tokensList)
                            tokensList.forEach((token) => {
                              const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
                              if (token.token.id !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
                                const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
                                if (realToken) {
                                  if (selectedRentType === 'Day') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
                                  if (selectedRentType === 'Week') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
                                  if (selectedRentType === 'Month') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
                                  if (selectedRentType === 'Year') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                                  tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                                  tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
                                  tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                  let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                  if (existingTokenIndex >= 0) {
                                    // console.log("Update rmm", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, rmmBalance);
                                    tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
                                    tokenBalance.tokens[existingTokenIndex].chain.push({
                                      id: 'rmm',
                                      amount: parseFloat(rmmBalance),
                                      wallet: wallet,
                                    });
                                  } else {
                                    // If the token doesn't exist, push a new entry
                                    tokenBalance.tokens.push({
                                      id: realToken.id,
                                      token: realToken,
                                      amount: parseFloat(rmmBalance),
                                      chain: [{ id: 'rmm', amount: parseFloat(rmmBalance), wallet: wallet }],
                                    });
                                  }
                                }
                              }
                            });
                        }
                      }

                      if (tokenBalance.chain === 'pool' || tokenBalance.chain === 'total') {
                        if (chain === 'pool') {
                          const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                          if (tokensList)
                            tokensList.forEach((token) => {
                              // console.log("chain", tokenBalance.chain, "walletBalance:", wallet, "token", token);
                              const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
                              const totalSupply = parseFloat(token.pair.totalSupply);
                              if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
                                const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
                                if (realToken) {
                                  const poolBalance = (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
                                  const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
                                  const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
                                  const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
                                  // if (Date.now() > getNextRonday(realToken)) {
                                  if (selectedRentType === 'Day') tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
                                  if (selectedRentType === 'Week') tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
                                  if (selectedRentType === 'Month') tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
                                  if (selectedRentType === 'Year') tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                  tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                  tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
                                  tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                  let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                  if (existingTokenIndex >= 0) {
                                    // console.log("Update pool", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, poolBalance);
                                    tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
                                    tokenBalance.tokens[existingTokenIndex].chain.push({
                                      id: 'pool',
                                      amount: parseFloat(poolBalance),
                                      wallet: wallet,
                                    });
                                  } else {
                                    // If the token doesn't exist, push a new entry
                                    tokenBalance.tokens.push({
                                      id: realToken.id,
                                      token: realToken,
                                      amount: parseFloat(poolBalance),
                                      chain: [{ id: 'pool', amount: parseFloat(poolBalance), wallet: wallet }],
                                    });
                                  }
                                  // }
                                }
                              }
                              if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
                                const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
                                if (realToken) {
                                  const poolBalance = (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
                                  const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
                                  const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
                                  const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
                                  // if (Date.now() > getNextRonday(realToken)) {
                                  if (selectedRentType === 'Day') tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
                                  if (selectedRentType === 'Week') tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
                                  if (selectedRentType === 'Month') tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
                                  if (selectedRentType === 'Year') tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                  tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                  tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
                                  tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                  let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                  if (existingTokenIndex >= 0) {
                                    // console.log("Update pool", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, poolBalance);
                                    tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
                                    tokenBalance.tokens[existingTokenIndex].chain.push({
                                      id: 'pool',
                                      amount: parseFloat(poolBalance),
                                      wallet: wallet,
                                    });
                                  } else {
                                    // If the token doesn't exist, push a new entry
                                    tokenBalance.tokens.push({
                                      id: realToken.id,
                                      token: realToken,
                                      amount: parseFloat(poolBalance),
                                      chain: [{ id: 'pool', amount: parseFloat(poolBalance), wallet: wallet }],
                                    });
                                  }
                                  // }
                                }
                              }
                            });
                        }
                      }
                    }
                  }
                }
              });
				});
			}
			setTokens(tokensBalance);
		}
	}, [realTokens, balanceWallets, holderWallets]);

	useEffect(() => {
		if (tokens) {
			if (!tokens[0].tokens.length > 0) return;
			const propertyTypes_nonOrdered = Array.from(new Set(tokens[0].tokens.map((data) => parseInt(data.token.propertyType)))).sort((a, b) => a - b);
			const propertyTypes = Array.from(new Set(propertyTypes_nonOrdered.map((data) => DEFAULT_PROPERTY_TYPE[data])));

			let propertyCount = propertyTypes.map((type) => ({
				realtokens: tokens[0].tokens.filter((data) => DEFAULT_PROPERTY_TYPE[data.token.propertyType] === type).length,
				capital: tokens[0].tokens.reduce(
					(acc, data) => acc + (DEFAULT_PROPERTY_TYPE[data.token.propertyType] === type ? data.amount * data.token.tokenPrice : 0 || 0),
					0
				),
			}));
			setPropertyCount(propertyCount);
		}
	}, [tokens,holderWallets,DEFAULT_PROPERTY_TYPE]);

	useEffect(() => {
		if (propertyCount && propertyCount?.length > 0) {
			const propertyTypes_nonOrdered = Array.from(new Set(tokens[0].tokens.map((data) => parseInt(data.token.propertyType)))).sort((a, b) => a - b);
			const propertyTypes = Array.from(new Set(propertyTypes_nonOrdered.map((data) => DEFAULT_PROPERTY_TYPE[data])));

			const totalProperties = parseInt(tokens[0].tokens.reduce((acc, data) => acc + (data.token.totalUnits > 0 ? 1 : 0 || 0), 0));
			const totalCapital = parseInt(
				tokens[0].tokens.reduce((acc, data) => acc + (data.amount > 0 ? data.amount * data.token.tokenPrice : 0 || 0), 0)
			);
			if (!propertyTypes) return;

			let data = propertyCount.map((val, index) => {
				const label = propertyTypes[index] ? propertyTypes[index].label : "Unknown";

				return settingsDashboard.selectedCalculType === "RealTokens"
					? {
							realtokens: val.realtokens,
							value: parseFloat(((100 * val.realtokens) / totalProperties).toFixed(1)),
							label: `${label} (${val.realtokens})`,
					  }
					: {
							realtokens: val.realtokens,
							value: parseFloat(((100 * val.capital) / totalCapital).toFixed(1)),
							label: `${label} (${val.capital > 1000000 ? (val.capital / 1000000).toFixed(2) + "M$" : (val.capital / 1000).toFixed(2) + "k$"})`,
					  };
			});

			const sortedData =
				settingsDashboard.selectedCalculType === "RealTokens"
					? data.sort((a, b) => b.realtokens - a.realtokens)
					: data.sort((a, b) => b.value - a.value);

			const chartData = {
				labels: sortedData.map((d) => d.label),
				datasets: [
					{
						data: sortedData.map((d) => d.value),
						backgroundColor: sortedData.map((_, i) => `hsl(${(i * 360) / sortedData.length}, 100%, ${theme.palette.mode === "dark"? "30%":"50%"})`),
					},
				],
			};
			setChartData(chartData);
		}
	}, [propertyCount, tokens, settingsDashboard.selectedCalculType,theme.palette.mode,DEFAULT_PROPERTY_TYPE]);

	const setSettingsDashboardChange = (key, newValue) => {
		setSettingsDashboard((prevSettings) => ({
			...prevSettings,
			[key]: newValue,
		}));
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: "right",
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						return `Percentage: ${context.raw.toFixed(1)}%`;
					},
				},
			},
		},
		layout: {
			padding: {
				top: 0,
				bottom: 0,
			},
		},
	};

	if (!settingsDashboard.isExpandedCharts) return <></>;
	if (!holderWallets.filter((holderWallet) => holderWallet.checked)[0]) return <></>;
	if (!chartData) return <></>;

	return (
		<Paper elevation={3} sx={{ m: 1, minWidth: "auto", border: 1, borderColor: "primary.main", borderRadius: 4 }}>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 0 }}>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Properties to
				</Typography>
				<Select
					sx={{ mr: 1, minWidth: 125 }}
					value={settingsDashboard.selectedCalculType}
					onChange={(e) => setSettingsDashboardChange("selectedCalculType", e.target.value)}
					size='small'
				>
					{settingsDashboard.calculType.map((p, index) => (
						<MenuItem key={index} value={p}>
							{p}
						</MenuItem>
					))}
				</Select>
				<Typography variant='h5' sx={{ m: 1, ml: 0, color: "primary.main" }}>
					Ratio
				</Typography>
			</Box>

			<Box sx={{ p: 1, display: "flex", justifyContent: "center", m: 0 }}>
				<Doughnut key={chartData ? chartData.labels.join("-") : "default-key"} data={chartData} options={options} width={500} height={300} />
			</Box>
		</Paper>
	);
}

export default PiePropertyType;
