import { useTheme } from '@mui/system';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { MyThemeContext } from '../../../context/theme-context';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import TableLevinswapRow from './TableLevinswapRow';

function TableLevinswap(props) {
  const { themeContext } = useContext(MyThemeContext);
  const { realTokens, holderWallets, balanceWallets, DEFAULT_PROPERTY_TYPE, settingsStats, setSettingsStats } = useContext(SettingsContext);
  const [selectedFullName, setSelectedFullName] = useState(() => {
    return sessionStorage.getItem('Realt__pitsbi__Stats_selectedFullName') || '';
  });

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
	
	setSettingsStats((prevSettings) => ({
      ...prevSettings,
      levin_rowsPerPage: event.target.value,
    }));
  };

  const selectedPropertyTypeChange = (newValue) => {
    setSettingsStats((prevSettings) => ({
      ...prevSettings,
      selectedPropertyType: newValue,
    }));
  };

  const [sortBy, setSortBy] = useState({ field: 'poolYield', order: 'desc', type: 'number' }); //strRentStartDate

  // ---------------------------------------------------------
  // ---- Timer pour mise à jour de selectedFullName    ----
  // -------------------------------------------------------
  const TIMEOUT_REFRESH = 2000; // Timer pour le refresh des offres en millisecondes
  const [state, setState] = useState(false);
  const counter = useRef(1);
  useEffect(() => {
    const newValue = sessionStorage.getItem('Realt__pitsbi__Stats_selectedFullName');
    if (!newValue) setSelectedFullName('');
    else if (newValue !== selectedFullName) setSelectedFullName(newValue);
    counter.current += 1;
    const timer = setTimeout(() => setState({ num: counter.current }), TIMEOUT_REFRESH);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    sessionStorage.setItem('Realt__pitsbi__Stats_selectedFullName', selectedFullName);
  }, [selectedFullName]);

  if (!realTokens) return <></>;
  // if (!holderWallets) return <></>;
  if (settingsStats.selectedPropertyType === null) return <></>;
  if (!DEFAULT_PROPERTY_TYPE) return <></>;
  if (realTokens.list.length === 0) return <></>;
  // let tokens = [...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType!=="equity_token")];
  let tokens = [...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0)];
  if (settingsStats.selectedPropertyType > 0) tokens = tokens.filter((data) => data.propertyType === settingsStats.selectedPropertyType);
  if (selectedFullName) tokens = tokens.filter((data) => data.fullName.toLowerCase().includes(selectedFullName.toLowerCase()));

  if (props.coinFilter) tokens = tokens.filter((data) => (data.pool.coinName ? data.pool.coinName.toLowerCase().includes(props.coinFilter.toLowerCase()) : false));
  else {
    const notokens = tokens.filter((data) => !data.pool.coinName);
    console.log('notokens:', notokens);
  }

  let tokensBalance = [{ label: 'Total', chain: 'total', count: 0, tokens: [] }];

  if (balanceWallets) {
    tokensBalance.forEach((tokenBalance) => {
      holderWallets
        .filter((holderWallet) => holderWallet.checked)
        .forEach((holderWallet) => {
          for (const wallet of Object.keys(balanceWallets)) {
            if (holderWallet.address === wallet) {
              for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                // if (tokenBalance.chain === "eth" || tokenBalance.chain === "total") {
                // 	if (chain === "eth") {
                // 		const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                // 		if (tokensList)
                // 			tokensList.forEach((token) => {
                // 				tokenBalance.count += parseFloat(token.amount);
                // 			});
                // 	}
                // }

                if (tokenBalance.chain === 'xdai' || tokenBalance.chain === 'total') {
                  if (chain === 'xdai') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList) {
                      tokensList.forEach((token) => {
                        tokenBalance.count += parseFloat(token.amount);
                        // console.log("token",token)
                        const realToken = tokens.filter((t) => t.gnosisContract === token.token.id)[0];
                        if (realToken && token.amount > 0) {
                          let storeToken = tokenBalance.tokens.filter((t) => t.id === realToken.id)[0];
                          if (storeToken) {
                            tokenBalance.tokens = tokenBalance.tokens.filter((t) => t.id !== realToken.id);
                            tokenBalance.tokens.push({
                              id: realToken.id,
                              name: realToken.fullName,
                              nb: storeToken.nb,
                              nbGnosis: storeToken.nbGnosis + parseFloat(token.amount),
                            });
                          } else tokenBalance.tokens.push({ id: realToken.id, name: realToken.fullName, nb: 0, nbGnosis: parseFloat(token.amount) });
                        }
                      });
                    }
                  }
                }

                // if (tokenBalance.chain === "rmm" || tokenBalance.chain === "total") {
                // 	if (chain === "rmm") {
                // 		const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                // 		if (tokensList)
                // 			tokensList.forEach((token) => {
                // 				const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
                // 				if (token.reserve.underlyingAsset !== "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d") {
                // 					tokenBalance.count += parseFloat(rmmBalance);
                // 				}
                // 			});
                // 	}
                // }

                // if (tokenBalance.chain === "rmmv3" || tokenBalance.chain === "total") {
                // 	if (chain === "rmmv3") {
                // 		const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                // 		if (tokensList)
                // 			tokensList.forEach((token) => {
                // 				const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
                // 				tokenBalance.count += parseFloat(rmmBalance);
                // 			});
                // 	}
                // }

                if (tokenBalance.chain === 'pool' || tokenBalance.chain === 'total') {
                  if (chain === 'pool') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        // console.log("chain", tokenBalance.chain, "walletBalance:", walletBalance.wallet, "token", token);
                        const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
                        const totalSupply = parseFloat(token.pair.totalSupply);
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
                          if (realToken)
                            if (realToken.pool.coinId) {
                              const poolBalance =
                                token.pair.token0.liquidity > 1000
                                  ? (liquidityTokenBalance * token.pair.token0.liquidity) / 10 ** 18 / totalSupply
                                  : (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
                              tokenBalance.count += parseFloat(poolBalance);
                              let storeToken = tokenBalance.tokens.filter((t) => t.id === realToken.id)[0];
                              if (storeToken) {
                                tokenBalance.tokens = tokenBalance.tokens.filter((t) => t.id !== realToken.id);
                                tokenBalance.tokens.push({
                                  id: realToken.id,
                                  name: realToken.fullName,
                                  nb: storeToken.nb + parseFloat(poolBalance),
                                  nbGnosis: storeToken.nbGnosis,
                                });
                              } else tokenBalance.tokens.push({ id: realToken.id, name: realToken.fullName, nb: parseFloat(poolBalance), nbGnosis: 0 });
                            }
                        }
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
                          if (realToken)
                            if (realToken.pool.coinId) {
                              const poolBalance =
                                token.pair.token1.liquidity > 1000
                                  ? (liquidityTokenBalance * token.pair.token1.liquidity) / 10 ** 18 / totalSupply
                                  : (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
                              tokenBalance.count += parseFloat(poolBalance);
                              let storeToken = tokenBalance.tokens.filter((t) => t.id === realToken.id)[0];
                              if (storeToken) {
                                tokenBalance.tokens = tokenBalance.tokens.filter((t) => t.id !== realToken.id);
                                tokenBalance.tokens.push({
                                  id: realToken.id,
                                  name: realToken.fullName,
                                  nb: storeToken.nb + parseFloat(poolBalance),
                                  nbGnosis: storeToken.nbGnosis,
                                });
                              } else tokenBalance.tokens.push({ id: realToken.id, name: realToken.fullName, nb: parseFloat(poolBalance), nbGnosis: 0 });
                            }
                        }
                      });
                  }
                }
              }
            }
          }
        });
    });
  }
  // console.log("tokensBalance", tokensBalance);

  const handleSort = (field, type) => {
    setSortBy((prevSortBy) => ({
      field,
      order: prevSortBy.field === field && prevSortBy.order === 'desc' ? 'asc' : 'desc',
      type,
    }));
  };

  tokens.forEach((x) => {
    x.init_price = x.historic.init_price;
    x.init_yield = x.historic.init_yield;
    if (!x.initialLaunchDate) x.strInitialLaunchDate = x.rentStartDate.date.slice(0, 10);
    else x.strInitialLaunchDate = x.initialLaunchDate.date.slice(0, 10);
    x.strRentStartDate = x.rentStartDate.date.slice(0, 10);
    x.realtTokens = x.pool.nbTokenRealt * x.pool.realtRatio;
    x.realtRent = (x.realtTokens * x.tokenPrice * x.annualPercentageYield) / 100;
    x.holderTokens = x.pool.nbTokenRealt * x.pool.holderRatio;
    x.holderRent = x.realtRent + (x.holderTokens * x.tokenPrice * x.annualPercentageYield) / 100;
    x.poolYield = ((x.realtTokens + x.holderTokens) / (2 * x.holderTokens)) * x.annualPercentageYield;

    x.nbToken = tokensBalance[0].tokens.filter((token) => {
      if (token.id === x.id) {
        // console.log("token", token);
        return token;
      } else return null;
    })[0];
    x.poolposition = x.nbToken ? x.nbToken.nb : 0;
    x.position = x.nbToken ? x.nbToken.nbGnosis : 0;

    // Step 1: Sort prices
    const sortedPrices = x.historic.prices.sort((a, b) => new Date(a.timsync) - new Date(b.timsync));
    // Step 2: Function to find the most recent price
    function findMostRecentPrice(timsync) {
      let mostRecentPrice = null;
      for (let price of sortedPrices) {
        if (new Date(price.timsync) <= new Date(timsync)) {
          mostRecentPrice = price.price;
        } else {
          break;
        }
      }
      return mostRecentPrice;
    }
    // Step 3: Merge data
    const updatedYields = x.historic.yields.map((yieldEntry) => {
      const mostRecentPrice = findMostRecentPrice(yieldEntry.timsync);
      return {
        ...yieldEntry,
        price: mostRecentPrice,
      };
    });
    x.valuation = x.tokenPrice - x.init_price;
    x.rentEarned = 0;
    let days_rented = 0;
    updatedYields.forEach((a) => {
      if (a.days_rented > 0) x.rentEarned += ((a.yield * a.price) / 36500) * a.days_rented;
      days_rented += a.days_rented;
    });
    x.roc = (x.valuation + x.rentEarned) / x.init_price;
    x.newYield = ((((x.valuation + x.rentEarned) / days_rented) * 365) / x.init_price) * 100;
    x.diffYield = x.newYield - x.init_yield;

    // console.log("x.roc", (x.valuation + x.rentEarned) / x.init_price, x.valuation, x.rentEarned, x.init_price);
    return x;
  });

  const sortedTokens = [...Object.values(tokens)].sort((a, b) => {
    const order = sortBy.order === 'asc' ? 1 : -1;
    if (a[sortBy.field]) {
      if (sortBy.type === 'number') return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
      if (sortBy.type === 'text') return order * a[sortBy.field].localeCompare(b[sortBy.field]);
      else return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
    }
  });

  if (sortedTokens.length / rowsPerPage < page && page > 0) handleChangePage(page, page - 1);

  // console.log("sortedTokens", sortedTokens);
  // sortedTokens.map((s, index) =>
  // 	s.pool ? console.log(index, "sortedTokens", s.pool.coinName) : console.log(index, "sortedTokens", "no pool defined !!!")
  // );

  return (
    <Box
      sx={{
        m: 1,
        px: 1,
        width: 1,
        border: 1,
        borderColor: 'primary.main',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          flexWrap: 'wrap',
          m: 0,
          px: 1,
          width: 1,
          border: 0,
          borderColor: 'primary.main',
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}>
        <Typography variant='h5' sx={{ color: 'primary.main' }}>
          Search
        </Typography>
        <TextField
          sx={{ ml: 1, minWidth: 200 }}
          label='Search in API fullName'
          onChange={(e) => setSelectedFullName(e.target.value)}
          variant='outlined'
          type='text'
          size='small'
          value={selectedFullName}
          InputProps={{
            // Utilisez "InputProps" avec un "P" majuscule
            endAdornment: selectedFullName ? ( // Condition modifiée pour une meilleure clarté
              <IconButton onClick={() => setSelectedFullName('')}>
                <ClearIcon />
              </IconButton>
            ) : null,
            style: {
              // Les styles doivent aussi être déplacés ici si spécifiques aux composants d'input
              textAlign: 'center',
              verticalAlign: 'center',
              padding: '0px 0px',
            },
          }}
        />
        <Typography variant='h5' sx={{ ml: 2, color: 'primary.main' }}>
          Property Type
        </Typography>
        <Select
          sx={{ ml: 1, minWidth: 125 }}
          value={settingsStats ? settingsStats.selectedPropertyType : 0}
          onChange={(e) => selectedPropertyTypeChange(e.target.value)}
          size='small'>
          {DEFAULT_PROPERTY_TYPE.filter((p) => !p.label.startsWith('Text_')).map((p) => (
            <MenuItem key={p.index} value={p.index}>
              {p.label}
            </MenuItem>
          ))}
        </Select>
        <TablePagination
          sx={{ ml: 'auto', minWidth: 125 }}
          rowsPerPageOptions={[10, 25, 50, 75, 100, 250, 500, 750]}
          component='div'
          count={sortedTokens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Paper elevation={3} sx={{ my: 1, width: 1, border: 0, borderColor: 'primary.main', borderRadius: 4 }}>
        <TableContainer component={Paper}>
          <Table sx={{ width: 1, background: 'background.paper' }} aria-label='sticky table'>
            {/* <Table sx={{ minWidth: 650 }} aria-label='simple table'> */}
            <TableHead>
              <TableRow
                accessKey='TokensTableHead'
                sx={{
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light,
                  '&:last-child th': { borderBottom: 0 },
                }}>
                <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1 }} colSpan={3}>
                  RealT
                </TableCell>
                <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('fullName', 'text')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    Token Name
                    {sortBy.field === 'fullName' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell
                  align='right'
                  sx={{ pl: 1, pr: 0, py: 1 }}
                  sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                  onClick={() => handleSort('position', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    Owned
                    {sortBy.field === 'position' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                {!isMatch && (
                  <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('strInitialLaunchDate', 'text')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      {!isMatch ? 'Acquisition' : 'Acq.'}
                      {sortBy.field === 'strInitialLaunchDate' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>
                )}
                <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('strRentStartDate', 'text')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    Rent Start
                    {sortBy.field === 'strRentStartDate' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('realtTokens', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    nb Token RealT
                    {sortBy.field === 'realtTokens' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('realtRent', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    Rent
                    {sortBy.field === 'realtRent' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('holderTokens', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    nb Token Holders
                    {sortBy.field === 'holderTokens' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('holderRent', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    Rent
                    {sortBy.field === 'holderRent' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('annualPercentageYield', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    Token Yield
                    {sortBy.field === 'annualPercentageYield' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('poolYield', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    Pool Yield
                    {sortBy.field === 'poolYield' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell
                  align='right'
                  sx={{ pl: 1, pr: 0, py: 1 }}
                  sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                  onClick={() => handleSort('poolposition', 'number')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    Owned in Pool
                    {sortBy.field === 'poolposition' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>Tokens Add</Box>
                </TableCell>
                <TableCell align='right' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>New Yield</Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTokens
                // .sort((a, b) => a.initialLaunchDate.date.localeCompare(b.initialLaunchDate.date))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((token) => (
                  <TableLevinswapRow key={token.id} token={token} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </Box> */}
      </Paper>
    </Box>
  );
}

export default TableLevinswap;
